import { Pipe, PipeTransform } from '@angular/core';
import { IconDefinition } from '@fortawesome/pro-thin-svg-icons';
import { PipeIconsService } from '../services/pipe-icons.service';

@Pipe({
  name: 'renderIcon',
  standalone: true,
})
export class RenderIconPipe implements PipeTransform {
  readonly iconGallery = this.pipeIconsService.allIcons;

  constructor(private pipeIconsService: PipeIconsService) {}

  transform(iconName: string) {
    const icon = this.iconGallery.find((icon) => icon.iconName === iconName);
    return icon as IconDefinition;
  }
}
