/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CancelQuizCommand } from '../model/cancelQuizCommand';
import { CancelQuizCommandResult } from '../model/cancelQuizCommandResult';
import { CreateUpdateQuizCommand } from '../model/createUpdateQuizCommand';
import { CreateUpdateQuizCommandResult } from '../model/createUpdateQuizCommandResult';
import { DeleteQuizCommand } from '../model/deleteQuizCommand';
import { GetQuizQueryResult } from '../model/getQuizQueryResult';
import { GetQuizStatisticsQueryResult } from '../model/getQuizStatisticsQueryResult';
import { GetQuizzesQueryResult } from '../model/getQuizzesQueryResult';
import { InlineResponse404 } from '../model/inlineResponse404';
import { ResourceScopeType } from '../model/resourceScopeType';
import { UpdateQuizCommand } from '../model/updateQuizCommand';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class QuizzesService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param filterLevel 
     * @param filterCreatedByUserUids 
     * @param bundleUid 
     * @param pageNumber 
     * @param pageSize 
     * @param searchText 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public quizzesGet(filterLevel?: ResourceScopeType, filterCreatedByUserUids?: Array<string>, bundleUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'body', reportProgress?: boolean): Observable<GetQuizzesQueryResult>;
    public quizzesGet(filterLevel?: ResourceScopeType, filterCreatedByUserUids?: Array<string>, bundleUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetQuizzesQueryResult>>;
    public quizzesGet(filterLevel?: ResourceScopeType, filterCreatedByUserUids?: Array<string>, bundleUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetQuizzesQueryResult>>;
    public quizzesGet(filterLevel?: ResourceScopeType, filterCreatedByUserUids?: Array<string>, bundleUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filterLevel !== undefined && filterLevel !== null) {
            queryParameters = queryParameters.set('Filter.Level', <any>filterLevel);
        }
        if (filterCreatedByUserUids) {
            filterCreatedByUserUids.forEach((element) => {
                queryParameters = queryParameters.append('Filter.CreatedByUserUids', <any>element);
            })
        }
        if (bundleUid !== undefined && bundleUid !== null) {
            queryParameters = queryParameters.set('BundleUid', <any>bundleUid);
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('PageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (searchText !== undefined && searchText !== null) {
            queryParameters = queryParameters.set('SearchText', <any>searchText);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetQuizzesQueryResult>('get',`${this.basePath}/Quizzes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public quizzesPost(body?: CreateUpdateQuizCommand, observe?: 'body', reportProgress?: boolean): Observable<CreateUpdateQuizCommandResult>;
    public quizzesPost(body?: CreateUpdateQuizCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateUpdateQuizCommandResult>>;
    public quizzesPost(body?: CreateUpdateQuizCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateUpdateQuizCommandResult>>;
    public quizzesPost(body?: CreateUpdateQuizCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreateUpdateQuizCommandResult>('post',`${this.basePath}/Quizzes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param teamUid 
     * @param organizationUid 
     * @param isMyClients 
     * @param groupUid 
     * @param quizEventUid 
     * @param pageNumber 
     * @param pageSize 
     * @param searchText 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public quizzesStatisticsGet(teamUid?: string, organizationUid?: string, isMyClients?: boolean, groupUid?: string, quizEventUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'body', reportProgress?: boolean): Observable<GetQuizStatisticsQueryResult>;
    public quizzesStatisticsGet(teamUid?: string, organizationUid?: string, isMyClients?: boolean, groupUid?: string, quizEventUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetQuizStatisticsQueryResult>>;
    public quizzesStatisticsGet(teamUid?: string, organizationUid?: string, isMyClients?: boolean, groupUid?: string, quizEventUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetQuizStatisticsQueryResult>>;
    public quizzesStatisticsGet(teamUid?: string, organizationUid?: string, isMyClients?: boolean, groupUid?: string, quizEventUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (teamUid !== undefined && teamUid !== null) {
            queryParameters = queryParameters.set('TeamUid', <any>teamUid);
        }
        if (organizationUid !== undefined && organizationUid !== null) {
            queryParameters = queryParameters.set('OrganizationUid', <any>organizationUid);
        }
        if (isMyClients !== undefined && isMyClients !== null) {
            queryParameters = queryParameters.set('IsMyClients', <any>isMyClients);
        }
        if (groupUid !== undefined && groupUid !== null) {
            queryParameters = queryParameters.set('GroupUid', <any>groupUid);
        }
        if (quizEventUid !== undefined && quizEventUid !== null) {
            queryParameters = queryParameters.set('QuizEventUid', <any>quizEventUid);
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('PageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (searchText !== undefined && searchText !== null) {
            queryParameters = queryParameters.set('SearchText', <any>searchText);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetQuizStatisticsQueryResult>('get',`${this.basePath}/Quizzes/Statistics`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public quizzesUidDelete(uid: string, body?: DeleteQuizCommand, observe?: 'body', reportProgress?: boolean): Observable<CancelQuizCommandResult>;
    public quizzesUidDelete(uid: string, body?: DeleteQuizCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CancelQuizCommandResult>>;
    public quizzesUidDelete(uid: string, body?: DeleteQuizCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CancelQuizCommandResult>>;
    public quizzesUidDelete(uid: string, body?: DeleteQuizCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling quizzesUidDelete.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CancelQuizCommandResult>('delete',`${this.basePath}/Quizzes/${encodeURIComponent(String(uid))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public quizzesUidGet(uid: string, observe?: 'body', reportProgress?: boolean): Observable<GetQuizQueryResult>;
    public quizzesUidGet(uid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetQuizQueryResult>>;
    public quizzesUidGet(uid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetQuizQueryResult>>;
    public quizzesUidGet(uid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling quizzesUidGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetQuizQueryResult>('get',`${this.basePath}/Quizzes/${encodeURIComponent(String(uid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public quizzesUidPatch(uid: string, body?: CancelQuizCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public quizzesUidPatch(uid: string, body?: CancelQuizCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public quizzesUidPatch(uid: string, body?: CancelQuizCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public quizzesUidPatch(uid: string, body?: CancelQuizCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling quizzesUidPatch.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('patch',`${this.basePath}/Quizzes/${encodeURIComponent(String(uid))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public quizzesUidPut(uid: string, body?: UpdateQuizCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public quizzesUidPut(uid: string, body?: UpdateQuizCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public quizzesUidPut(uid: string, body?: UpdateQuizCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public quizzesUidPut(uid: string, body?: UpdateQuizCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling quizzesUidPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Quizzes/${encodeURIComponent(String(uid))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
