/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateQuestionCommand } from '../model/createQuestionCommand';
import { CreateQuestionCommandResult } from '../model/createQuestionCommandResult';
import { DeleteQuestionCommandResult } from '../model/deleteQuestionCommandResult';
import { GetQuestionQueryResult } from '../model/getQuestionQueryResult';
import { GetQuestionsQueryResult } from '../model/getQuestionsQueryResult';
import { InlineResponse404 } from '../model/inlineResponse404';
import { QuestionScaleType } from '../model/questionScaleType';
import { ResourceScopeType } from '../model/resourceScopeType';
import { UpdateQuestionCommand } from '../model/updateQuestionCommand';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class QuestionsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param filterLevel 
     * @param filterCreatedByUserUids 
     * @param filterExcludedQuestions 
     * @param filterScaleType 
     * @param bundleUid 
     * @param pageNumber 
     * @param pageSize 
     * @param searchText 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public questionsGet(filterLevel?: ResourceScopeType, filterCreatedByUserUids?: Array<string>, filterExcludedQuestions?: Array<string>, filterScaleType?: QuestionScaleType, bundleUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'body', reportProgress?: boolean): Observable<GetQuestionsQueryResult>;
    public questionsGet(filterLevel?: ResourceScopeType, filterCreatedByUserUids?: Array<string>, filterExcludedQuestions?: Array<string>, filterScaleType?: QuestionScaleType, bundleUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetQuestionsQueryResult>>;
    public questionsGet(filterLevel?: ResourceScopeType, filterCreatedByUserUids?: Array<string>, filterExcludedQuestions?: Array<string>, filterScaleType?: QuestionScaleType, bundleUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetQuestionsQueryResult>>;
    public questionsGet(filterLevel?: ResourceScopeType, filterCreatedByUserUids?: Array<string>, filterExcludedQuestions?: Array<string>, filterScaleType?: QuestionScaleType, bundleUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filterLevel !== undefined && filterLevel !== null) {
            queryParameters = queryParameters.set('Filter.Level', <any>filterLevel);
        }
        if (filterCreatedByUserUids) {
            filterCreatedByUserUids.forEach((element) => {
                queryParameters = queryParameters.append('Filter.CreatedByUserUids', <any>element);
            })
        }
        if (filterExcludedQuestions) {
            filterExcludedQuestions.forEach((element) => {
                queryParameters = queryParameters.append('Filter.ExcludedQuestions', <any>element);
            })
        }
        if (filterScaleType !== undefined && filterScaleType !== null) {
            queryParameters = queryParameters.set('Filter.ScaleType', <any>filterScaleType);
        }
        if (bundleUid !== undefined && bundleUid !== null) {
            queryParameters = queryParameters.set('BundleUid', <any>bundleUid);
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('PageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (searchText !== undefined && searchText !== null) {
            queryParameters = queryParameters.set('SearchText', <any>searchText);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetQuestionsQueryResult>('get',`${this.basePath}/Questions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public questionsPost(body?: CreateQuestionCommand, observe?: 'body', reportProgress?: boolean): Observable<CreateQuestionCommandResult>;
    public questionsPost(body?: CreateQuestionCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateQuestionCommandResult>>;
    public questionsPost(body?: CreateQuestionCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateQuestionCommandResult>>;
    public questionsPost(body?: CreateQuestionCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreateQuestionCommandResult>('post',`${this.basePath}/Questions`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public questionsUidDelete(uid: string, observe?: 'body', reportProgress?: boolean): Observable<DeleteQuestionCommandResult>;
    public questionsUidDelete(uid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeleteQuestionCommandResult>>;
    public questionsUidDelete(uid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeleteQuestionCommandResult>>;
    public questionsUidDelete(uid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling questionsUidDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DeleteQuestionCommandResult>('delete',`${this.basePath}/Questions/${encodeURIComponent(String(uid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public questionsUidGet(uid: string, observe?: 'body', reportProgress?: boolean): Observable<GetQuestionQueryResult>;
    public questionsUidGet(uid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetQuestionQueryResult>>;
    public questionsUidGet(uid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetQuestionQueryResult>>;
    public questionsUidGet(uid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling questionsUidGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetQuestionQueryResult>('get',`${this.basePath}/Questions/${encodeURIComponent(String(uid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates question
     * UserUid update QuestionUid 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public questionsUidPut(uid: string, body?: UpdateQuestionCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public questionsUidPut(uid: string, body?: UpdateQuestionCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public questionsUidPut(uid: string, body?: UpdateQuestionCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public questionsUidPut(uid: string, body?: UpdateQuestionCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling questionsUidPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Questions/${encodeURIComponent(String(uid))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
