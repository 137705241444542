import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat',
  standalone: true,
})
export class TimeFormatPipe implements PipeTransform {
  transform(value: string | undefined): string | undefined {
    if (!value) return undefined;

    const timeParts = value.split(':').map((part) => parseInt(part, 10));

    const paddedTimeParts = timeParts.map((part) =>
      part.toString().padStart(2, '0'),
    );

    switch (paddedTimeParts.length) {
      case 2:
        return `00:${paddedTimeParts[0]}:${paddedTimeParts[1]}`;
      case 3:
        return `${paddedTimeParts[0]}:${paddedTimeParts[1]}:${paddedTimeParts[2]}`;
      default:
        return undefined;
    }
  }
}
