import { Directive, ElementRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[appScrollDirective]',
  standalone: true,
})
export class ScrollDirective {
  previousScrollHeightMinusTop: number; // the variable which stores the distance
  readyFor: string;
  toReset = false;

  constructor(public elementRef: ElementRef) {
    this.previousScrollHeightMinusTop = 0;
    this.readyFor = 'up';
    this.restore();
  }

  reset() {
    this.previousScrollHeightMinusTop = 0;
    this.readyFor = 'up';
    this.elementRef.nativeElement.scrollTop =
      this.elementRef.nativeElement.scrollHeight;
    // resetting the scroll position to bottom because that is where chats start.
  }

  restore() {
    if (this.readyFor === 'up') {
      this.elementRef.nativeElement.scrollTop =
        this.elementRef.nativeElement.scrollHeight -
        this.previousScrollHeightMinusTop;
      // restoring the scroll position to the one stored earlier
    }
  }

  prepareFor(direction: 'up' | 'down') {
    this.toReset = true;
    this.readyFor = direction || 'up';
    this.previousScrollHeightMinusTop =
      this.elementRef.nativeElement.scrollHeight -
      this.elementRef.nativeElement.scrollTop;
    // the current position is stored before new messages are loaded
  }

  prepareForDown() {
    this.toReset = true;
    this.readyFor = 'down';
    this.previousScrollHeightMinusTop = this.elementRef.nativeElement.scrollTop;
  }
}
