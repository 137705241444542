import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@jsverse/transloco';
import { DateTime } from 'luxon';

@Pipe({
  name: 'messageDate',
  standalone: true,
})
export class MessageDatePipe implements PipeTransform {
  transform(
    createdDateTimeUTC: string | undefined,
    activeLang: string,
  ): string | undefined {
    if (!createdDateTimeUTC) return;

    const currentDate = DateTime.local().startOf('day');
    const comparisonDate = DateTime.fromISO(createdDateTimeUTC, {
      zone: 'local',
    }).startOf('day');

    if (comparisonDate.hasSame(currentDate, 'day')) {
      return translate('Today');
    }

    if (comparisonDate.hasSame(currentDate.minus({ days: 1 }), 'day')) {
      return translate('Yesterday');
    }

    const formattedDate = comparisonDate.setLocale(activeLang ?? 'en').toLocaleString(DateTime.DATE_FULL)

    return formattedDate;
  }
}
