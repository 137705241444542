import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortenCounter',
  standalone: true,
})
export class ShortenCounterPipe implements PipeTransform {
  transform(totalUnreadCount: number | undefined) {
    if (!totalUnreadCount) return;
    return totalUnreadCount > 99 ? '99+' : totalUnreadCount;
  }
}
