/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateExerciseTemplateCommand } from '../model/createExerciseTemplateCommand';
import { CreateExerciseTemplateCommandResult } from '../model/createExerciseTemplateCommandResult';
import { DeleteExerciseTemplateCommandResult } from '../model/deleteExerciseTemplateCommandResult';
import { GetExerciseCategoryQueryResult } from '../model/getExerciseCategoryQueryResult';
import { GetExerciseTemplateQueryResult } from '../model/getExerciseTemplateQueryResult';
import { GetExercisesQueryResult } from '../model/getExercisesQueryResult';
import { InlineResponse404 } from '../model/inlineResponse404';
import { UpdateExerciseTemplateCommand } from '../model/updateExerciseTemplateCommand';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ExerciseService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Creates exercise template for UserUid 
     * UserUid creates exercise 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exerciseExercisePost(body?: CreateExerciseTemplateCommand, observe?: 'body', reportProgress?: boolean): Observable<CreateExerciseTemplateCommandResult>;
    public exerciseExercisePost(body?: CreateExerciseTemplateCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateExerciseTemplateCommandResult>>;
    public exerciseExercisePost(body?: CreateExerciseTemplateCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateExerciseTemplateCommandResult>>;
    public exerciseExercisePost(body?: CreateExerciseTemplateCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreateExerciseTemplateCommandResult>('post',`${this.basePath}/Exercise/Exercise`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all users and teams exercises 
     * UserUid gets all his and exercises defined by his team
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exerciseGetCategoriesGet(observe?: 'body', reportProgress?: boolean): Observable<GetExerciseCategoryQueryResult>;
    public exerciseGetCategoriesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetExerciseCategoryQueryResult>>;
    public exerciseGetCategoriesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetExerciseCategoryQueryResult>>;
    public exerciseGetCategoriesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetExerciseCategoryQueryResult>('get',`${this.basePath}/Exercise/GetCategories`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all users and teams exercises 
     * UserUid gets all his and exercises defined by his team
     * @param filterCategoryUid 
     * @param filterEquipments 
     * @param filterPlanes 
     * @param filterPrimaryMuscles 
     * @param filterCreatedByUserUids 
     * @param bundleUid 
     * @param pageNumber 
     * @param pageSize 
     * @param searchText 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exerciseGetCollectionGet(filterCategoryUid?: string, filterEquipments?: Array<string>, filterPlanes?: Array<string>, filterPrimaryMuscles?: Array<string>, filterCreatedByUserUids?: Array<string>, bundleUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'body', reportProgress?: boolean): Observable<GetExercisesQueryResult>;
    public exerciseGetCollectionGet(filterCategoryUid?: string, filterEquipments?: Array<string>, filterPlanes?: Array<string>, filterPrimaryMuscles?: Array<string>, filterCreatedByUserUids?: Array<string>, bundleUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetExercisesQueryResult>>;
    public exerciseGetCollectionGet(filterCategoryUid?: string, filterEquipments?: Array<string>, filterPlanes?: Array<string>, filterPrimaryMuscles?: Array<string>, filterCreatedByUserUids?: Array<string>, bundleUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetExercisesQueryResult>>;
    public exerciseGetCollectionGet(filterCategoryUid?: string, filterEquipments?: Array<string>, filterPlanes?: Array<string>, filterPrimaryMuscles?: Array<string>, filterCreatedByUserUids?: Array<string>, bundleUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filterCategoryUid !== undefined && filterCategoryUid !== null) {
            queryParameters = queryParameters.set('Filter.CategoryUid', <any>filterCategoryUid);
        }
        if (filterEquipments) {
            filterEquipments.forEach((element) => {
                queryParameters = queryParameters.append('Filter.Equipments', <any>element);
            })
        }
        if (filterPlanes) {
            filterPlanes.forEach((element) => {
                queryParameters = queryParameters.append('Filter.Planes', <any>element);
            })
        }
        if (filterPrimaryMuscles) {
            filterPrimaryMuscles.forEach((element) => {
                queryParameters = queryParameters.append('Filter.PrimaryMuscles', <any>element);
            })
        }
        if (filterCreatedByUserUids) {
            filterCreatedByUserUids.forEach((element) => {
                queryParameters = queryParameters.append('Filter.CreatedByUserUids', <any>element);
            })
        }
        if (bundleUid !== undefined && bundleUid !== null) {
            queryParameters = queryParameters.set('BundleUid', <any>bundleUid);
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('PageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (searchText !== undefined && searchText !== null) {
            queryParameters = queryParameters.set('SearchText', <any>searchText);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetExercisesQueryResult>('get',`${this.basePath}/Exercise/GetCollection`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get single exercise by 
     * 
     * @param exerciseUid 
     * @param isPreview 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exerciseGetSingleGet(exerciseUid?: string, isPreview?: boolean, observe?: 'body', reportProgress?: boolean): Observable<GetExerciseTemplateQueryResult>;
    public exerciseGetSingleGet(exerciseUid?: string, isPreview?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetExerciseTemplateQueryResult>>;
    public exerciseGetSingleGet(exerciseUid?: string, isPreview?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetExerciseTemplateQueryResult>>;
    public exerciseGetSingleGet(exerciseUid?: string, isPreview?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (exerciseUid !== undefined && exerciseUid !== null) {
            queryParameters = queryParameters.set('ExerciseUid', <any>exerciseUid);
        }
        if (isPreview !== undefined && isPreview !== null) {
            queryParameters = queryParameters.set('IsPreview', <any>isPreview);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetExerciseTemplateQueryResult>('get',`${this.basePath}/Exercise/GetSingle`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a exercise template.
     * UserUid deletes ExerciseUid [SOFT DELETE]
     * @param uid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exerciseUidDelete(uid: string, observe?: 'body', reportProgress?: boolean): Observable<DeleteExerciseTemplateCommandResult>;
    public exerciseUidDelete(uid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeleteExerciseTemplateCommandResult>>;
    public exerciseUidDelete(uid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeleteExerciseTemplateCommandResult>>;
    public exerciseUidDelete(uid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling exerciseUidDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DeleteExerciseTemplateCommandResult>('delete',`${this.basePath}/Exercise/${encodeURIComponent(String(uid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates exercise template with provided ExerciseUid
     * UserUid update ExerciseUid with 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exerciseUidPut(uid: string, body?: UpdateExerciseTemplateCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public exerciseUidPut(uid: string, body?: UpdateExerciseTemplateCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public exerciseUidPut(uid: string, body?: UpdateExerciseTemplateCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public exerciseUidPut(uid: string, body?: UpdateExerciseTemplateCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling exerciseUidPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Exercise/${encodeURIComponent(String(uid))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
