import { explicitEffect } from 'ngxtension/explicit-effect';
import { input, output } from '@angular/core';
import { Directive, ElementRef, inject } from '@angular/core';

@Directive({
  selector: '[appTruncate]',
  standalone: true,
})
export class TruncateDirective {
  private readonly el = inject(ElementRef);

  maxRows = input.required<number>({ alias: 'appTruncate' });
  fullText = input.required<string>();

  showMoreLess = output<boolean>();
  showButton = output<boolean>();

  private isTruncated: boolean = false;

  constructor() {
    explicitEffect([this.fullText], ([fullText]) => {
      if (!fullText) return;

      this.truncateText(fullText);
    });
  }

  private truncateText(fullText: string) {
    const lineHeight = parseInt(
      window.getComputedStyle(this.el.nativeElement).lineHeight,
    );
    const maxHeight = lineHeight * this.maxRows();

    this.el.nativeElement.innerHTML = fullText;

    const segments = fullText.split('<br/>');
    let reconstructed = '';

    if (segments.length <= this.maxRows()) {
      this.isTruncated = false;
      this.showMoreLess.emit(false);
      this.showButton.emit(false);
      return;
    }

    for (let i = 0; i < segments.length; i++) {
      let testContent = reconstructed + (i > 0 ? '<br/>' : '') + segments[i];
      this.el.nativeElement.innerHTML = testContent + '...';

      if (this.el.nativeElement.scrollHeight > maxHeight) {
        this.el.nativeElement.innerHTML = reconstructed + '...';
        this.isTruncated = true;
        this.showMoreLess.emit(true);
        this.showButton.emit(true);
        break;
      }

      reconstructed += (i > 0 ? '<br/>' : '') + segments[i];
    }
  }

  toggleText() {
    if (this.isTruncated) {
      this.el.nativeElement.innerHTML = this.fullText();
      this.isTruncated = false;
      this.showMoreLess.emit(false);
      this.showButton.emit(true);
    } else {
      this.truncateText(this.fullText());
    }
  }
}
