import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@jsverse/transloco';
import { DateTime } from 'luxon';

@Pipe({
  name: 'groupChatDate',
  standalone: true,
})
export class GroupChatDatePipe implements PipeTransform {
  transform(
    createdDateTimeUTC: string | undefined,
    timeZone: string,
    activeLang?: string,
  ) {
    if (!createdDateTimeUTC) return;

    const locale = activeLang ?? 'en';

    const currentDate = DateTime.local({ zone: timeZone });
    const comparisonDate = DateTime.fromISO(createdDateTimeUTC, {
      zone: timeZone,
    }).setLocale(locale);

    // Determine the appropriate prefix and time string
    let prefix: string;
    let dayDescriptor: string | null = null;

    if (comparisonDate.hasSame(currentDate, 'day')) {
      dayDescriptor = translate('Today at ', {}, locale);
    } else if (comparisonDate.hasSame(currentDate.plus({ days: 1 }), 'day')) {
      dayDescriptor = translate('Tomorrow at ', {}, locale);
    } else if (comparisonDate.hasSame(currentDate.minus({ days: 1 }), 'day')) {
      dayDescriptor = translate('Yesterday at ', {}, locale);
    }

    if (dayDescriptor) {
      // Format time according to locale conventions
      const timeString = comparisonDate.toLocaleString(DateTime.TIME_SIMPLE);
      prefix = dayDescriptor + timeString;
    } else {
      // Format date according to locale conventions
      prefix = comparisonDate.toLocaleString(DateTime.DATE_FULL);
    }

    return prefix;
  }
}
