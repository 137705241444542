import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { ShortenTextPipe } from '../../pipes/shorten-text.pipe';

@Component({
  selector: 'goatsports-system-message-translation',
  standalone: true,
  imports: [CommonModule, TranslocoModule, ShortenTextPipe],
  templateUrl: './system-message-translation.component.html',
  styleUrls: ['./system-message-translation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemMessageTranslationComponent {
  @Input() systemMessageParams!: any;
  @Input() isShortenText!: boolean;
  @Input() color = '#73778c';
}
