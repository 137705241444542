import { Directive, HostListener } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[matInput], textarea[matInput]',
  standalone: true,
})
export class DisableEnterDirective {
  @HostListener('keydown.enter', ['$event'])
  onKeydownEnter(event: KeyboardEvent) {
    event.preventDefault();
  }
}
