import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addSpacesToCamelCase',
  standalone: true,
})
export class AddSpacesToCamelCasePipe implements PipeTransform {
  transform(value: string | null | undefined): string {
    if (!value) {
      return '';
    }
    // Improved regex to avoid adding space before the first character
    return value.replace(/(?!^)([A-Z]|[0-9]+)/g, ' $1');
  }
}
