/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateOrganizationCommand } from '../model/createOrganizationCommand';
import { CreateOrganizationCommandResult } from '../model/createOrganizationCommandResult';
import { GetOrganizationQueryResult } from '../model/getOrganizationQueryResult';
import { GetOrganizationQuotesQueryResult } from '../model/getOrganizationQuotesQueryResult';
import { GetOrganizationsOverviewQuery } from '../model/getOrganizationsOverviewQuery';
import { GetOrganizationsOverviewQueryResult } from '../model/getOrganizationsOverviewQueryResult';
import { GetOrganizationsQueryResult } from '../model/getOrganizationsQueryResult';
import { InlineResponse404 } from '../model/inlineResponse404';
import { OrganizationsSortSpecification } from '../model/organizationsSortSpecification';
import { TeamsSortSpecification } from '../model/teamsSortSpecification';
import { UpdateOrganizationCommand } from '../model/updateOrganizationCommand';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class OrganizationsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param sort 
     * @param pageNumber 
     * @param pageSize 
     * @param searchText 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationsGet(sort?: Array<OrganizationsSortSpecification>, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'body', reportProgress?: boolean): Observable<GetOrganizationsQueryResult>;
    public organizationsGet(sort?: Array<OrganizationsSortSpecification>, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetOrganizationsQueryResult>>;
    public organizationsGet(sort?: Array<OrganizationsSortSpecification>, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetOrganizationsQueryResult>>;
    public organizationsGet(sort?: Array<OrganizationsSortSpecification>, pageNumber?: number, pageSize?: number, searchText?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('Sort', <any>element);
            })
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('PageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (searchText !== undefined && searchText !== null) {
            queryParameters = queryParameters.set('SearchText', <any>searchText);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetOrganizationsQueryResult>('get',`${this.basePath}/Organizations`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param request 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationsGetOverviewGet(request?: GetOrganizationsOverviewQuery, observe?: 'body', reportProgress?: boolean): Observable<GetOrganizationsOverviewQueryResult>;
    public organizationsGetOverviewGet(request?: GetOrganizationsOverviewQuery, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetOrganizationsOverviewQueryResult>>;
    public organizationsGetOverviewGet(request?: GetOrganizationsOverviewQuery, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetOrganizationsOverviewQueryResult>>;
    public organizationsGetOverviewGet(request?: GetOrganizationsOverviewQuery, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (request !== undefined && request !== null) {
            queryParameters = queryParameters.set('request', <any>request);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetOrganizationsOverviewQueryResult>('get',`${this.basePath}/Organizations/GetOverview`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates organization with Name and Quotes
     * UserUid Creates OrganizationUid with Name and Quotes 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationsPost(body?: CreateOrganizationCommand, observe?: 'body', reportProgress?: boolean): Observable<CreateOrganizationCommandResult>;
    public organizationsPost(body?: CreateOrganizationCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateOrganizationCommandResult>>;
    public organizationsPost(body?: CreateOrganizationCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateOrganizationCommandResult>>;
    public organizationsPost(body?: CreateOrganizationCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreateOrganizationCommandResult>('post',`${this.basePath}/Organizations`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get organization quotes
     * UserUid gets organization quotes
     * @param uid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationsQuotesUidGet(uid?: string, observe?: 'body', reportProgress?: boolean): Observable<GetOrganizationQuotesQueryResult>;
    public organizationsQuotesUidGet(uid?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetOrganizationQuotesQueryResult>>;
    public organizationsQuotesUidGet(uid?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetOrganizationQuotesQueryResult>>;
    public organizationsQuotesUidGet(uid?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (uid !== undefined && uid !== null) {
            queryParameters = queryParameters.set('Uid', <any>uid);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetOrganizationQuotesQueryResult>('get',`${this.basePath}/Organizations/Quotes/uid`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get single organization
     * UserUid gets organization details
     * @param uid 
     * @param sort 
     * @param pageNumber 
     * @param pageSize 
     * @param searchText 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationsUidGet(uid?: string, sort?: Array<TeamsSortSpecification>, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'body', reportProgress?: boolean): Observable<GetOrganizationQueryResult>;
    public organizationsUidGet(uid?: string, sort?: Array<TeamsSortSpecification>, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetOrganizationQueryResult>>;
    public organizationsUidGet(uid?: string, sort?: Array<TeamsSortSpecification>, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetOrganizationQueryResult>>;
    public organizationsUidGet(uid?: string, sort?: Array<TeamsSortSpecification>, pageNumber?: number, pageSize?: number, searchText?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (uid !== undefined && uid !== null) {
            queryParameters = queryParameters.set('Uid', <any>uid);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('Sort', <any>element);
            })
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('PageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (searchText !== undefined && searchText !== null) {
            queryParameters = queryParameters.set('SearchText', <any>searchText);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetOrganizationQueryResult>('get',`${this.basePath}/Organizations/uid`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates organization
     * UserUid update OrganizationUid 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public organizationsUidPut(uid: string, body?: UpdateOrganizationCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public organizationsUidPut(uid: string, body?: UpdateOrganizationCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public organizationsUidPut(uid: string, body?: UpdateOrganizationCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public organizationsUidPut(uid: string, body?: UpdateOrganizationCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling organizationsUidPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Organizations/${encodeURIComponent(String(uid))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
