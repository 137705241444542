import { effect, untracked } from '@angular/core';

/**
 * This explicit effect function will take the dependencies and the function to run when the dependencies change.
 *
 * @example
 * ```typescript
 * import { explicitEffect } from 'ngxtension/explicit-effect';
 *
 * const count = signal(0);
 * const state = signal('idle');
 *
 * explicitEffect([count, state], ([count, state], cleanup) => {
 *   console.log('count updated', count, state);
 *
 *   cleanup(() => {
 *     console.log('cleanup');
 *   });
 * });
 * ```
 *
 * @param deps - The dependencies that the effect will run on
 * @param fn - The function to run when the dependencies change
 * @param options - The options for the effect with the addition of defer (it allows the computation to run on first change, not immediately)
 */
function explicitEffect(deps, fn, options) {
  let defer = options && options.defer;
  return effect(onCleanup => {
    const depValues = deps.map(s => s());
    untracked(() => {
      if (!defer) {
        fn(depValues, onCleanup);
      }
      defer = false;
    });
  }, options);
}

/**
 * Generated bundle index. Do not edit.
 */

export { explicitEffect };
