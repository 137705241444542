/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AssignMealCommand } from '../model/assignMealCommand';
import { AssignMealCommandResult } from '../model/assignMealCommandResult';
import { AssignPlanCommand } from '../model/assignPlanCommand';
import { AssignUserMealCommand } from '../model/assignUserMealCommand';
import { AssignUserMealCommandResult } from '../model/assignUserMealCommandResult';
import { DeleteUserMealCommand } from '../model/deleteUserMealCommand';
import { DeleteUserMealCommandResult } from '../model/deleteUserMealCommandResult';
import { GetMealSubmissionQueryResult } from '../model/getMealSubmissionQueryResult';
import { GetUserMealCalendarQueryResult } from '../model/getUserMealCalendarQueryResult';
import { GetUserMealQueryResult } from '../model/getUserMealQueryResult';
import { GetUserMealsScheduleQueryResult } from '../model/getUserMealsScheduleQueryResult';
import { InlineResponse404 } from '../model/inlineResponse404';
import { UpdateUserMealCommand } from '../model/updateUserMealCommand';
import { UpdateUserMealScheduleCommand } from '../model/updateUserMealScheduleCommand';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UserMealsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Coach assigning plan to user on PORTAL
     * Coach assings PlanUid to UserUid
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userMealsAssignPlanPost(body?: AssignPlanCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userMealsAssignPlanPost(body?: AssignPlanCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userMealsAssignPlanPost(body?: AssignPlanCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userMealsAssignPlanPost(body?: AssignPlanCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/UserMeals/AssignPlan`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Client assigning meal to himeslef on APP
     * UserUid assings MealUid to desired date
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userMealsAssignPost(body?: AssignUserMealCommand, observe?: 'body', reportProgress?: boolean): Observable<AssignUserMealCommandResult>;
    public userMealsAssignPost(body?: AssignUserMealCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AssignUserMealCommandResult>>;
    public userMealsAssignPost(body?: AssignUserMealCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AssignUserMealCommandResult>>;
    public userMealsAssignPost(body?: AssignUserMealCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AssignUserMealCommandResult>('post',`${this.basePath}/UserMeals/Assign`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Coach assigning meal to user on PORTAL
     * Coach assings MealUid to UserUid
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userMealsAssignToClientPost(body?: AssignMealCommand, observe?: 'body', reportProgress?: boolean): Observable<AssignMealCommandResult>;
    public userMealsAssignToClientPost(body?: AssignMealCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AssignMealCommandResult>>;
    public userMealsAssignToClientPost(body?: AssignMealCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AssignMealCommandResult>>;
    public userMealsAssignToClientPost(body?: AssignMealCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AssignMealCommandResult>('post',`${this.basePath}/UserMeals/AssignToClient`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [COACH] Gets user plan days 
     * UserUid gets meal plan schedule for ClientUid
     * @param clientUid 
     * @param startDateLocal 
     * @param endDateLocal 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userMealsGet(clientUid?: string, startDateLocal?: string, endDateLocal?: string, observe?: 'body', reportProgress?: boolean): Observable<GetUserMealsScheduleQueryResult>;
    public userMealsGet(clientUid?: string, startDateLocal?: string, endDateLocal?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserMealsScheduleQueryResult>>;
    public userMealsGet(clientUid?: string, startDateLocal?: string, endDateLocal?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserMealsScheduleQueryResult>>;
    public userMealsGet(clientUid?: string, startDateLocal?: string, endDateLocal?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (clientUid !== undefined && clientUid !== null) {
            queryParameters = queryParameters.set('ClientUid', <any>clientUid);
        }
        if (startDateLocal !== undefined && startDateLocal !== null) {
            queryParameters = queryParameters.set('StartDateLocal', <any>startDateLocal);
        }
        if (endDateLocal !== undefined && endDateLocal !== null) {
            queryParameters = queryParameters.set('EndDateLocal', <any>endDateLocal);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUserMealsScheduleQueryResult>('get',`${this.basePath}/UserMeals`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userMealsOrdinalReorderPut(body?: UpdateUserMealScheduleCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userMealsOrdinalReorderPut(body?: UpdateUserMealScheduleCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userMealsOrdinalReorderPut(body?: UpdateUserMealScheduleCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userMealsOrdinalReorderPut(body?: UpdateUserMealScheduleCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/UserMeals/OrdinalReorder`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userMealsUidDelete(uid: string, body?: DeleteUserMealCommand, observe?: 'body', reportProgress?: boolean): Observable<DeleteUserMealCommandResult>;
    public userMealsUidDelete(uid: string, body?: DeleteUserMealCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeleteUserMealCommandResult>>;
    public userMealsUidDelete(uid: string, body?: DeleteUserMealCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeleteUserMealCommandResult>>;
    public userMealsUidDelete(uid: string, body?: DeleteUserMealCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling userMealsUidDelete.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DeleteUserMealCommandResult>('delete',`${this.basePath}/UserMeals/${encodeURIComponent(String(uid))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get single user meal
     * UserUid gets sinlge meal by MealUid
     * @param uid 
     * @param selectedDateLocal 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userMealsUidGet(uid: string, selectedDateLocal?: string, observe?: 'body', reportProgress?: boolean): Observable<GetUserMealQueryResult>;
    public userMealsUidGet(uid: string, selectedDateLocal?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserMealQueryResult>>;
    public userMealsUidGet(uid: string, selectedDateLocal?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserMealQueryResult>>;
    public userMealsUidGet(uid: string, selectedDateLocal?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling userMealsUidGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (selectedDateLocal !== undefined && selectedDateLocal !== null) {
            queryParameters = queryParameters.set('SelectedDateLocal', <any>selectedDateLocal);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUserMealQueryResult>('get',`${this.basePath}/UserMeals/${encodeURIComponent(String(uid))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userMealsUidPut(uid: string, body?: UpdateUserMealCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public userMealsUidPut(uid: string, body?: UpdateUserMealCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public userMealsUidPut(uid: string, body?: UpdateUserMealCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public userMealsUidPut(uid: string, body?: UpdateUserMealCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling userMealsUidPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/UserMeals/${encodeURIComponent(String(uid))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get users meal submission for a day
     * 
     * @param assignedDateLocal 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userMealsUserMealSubmissionGet(assignedDateLocal?: string, observe?: 'body', reportProgress?: boolean): Observable<GetUserMealCalendarQueryResult>;
    public userMealsUserMealSubmissionGet(assignedDateLocal?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserMealCalendarQueryResult>>;
    public userMealsUserMealSubmissionGet(assignedDateLocal?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserMealCalendarQueryResult>>;
    public userMealsUserMealSubmissionGet(assignedDateLocal?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (assignedDateLocal !== undefined && assignedDateLocal !== null) {
            queryParameters = queryParameters.set('AssignedDateLocal', <any>assignedDateLocal);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUserMealCalendarQueryResult>('get',`${this.basePath}/UserMeals/UserMealSubmission`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get users meals submission days for period
     * UserUid gets meal schedule
     * @param startDateLocal 
     * @param endDateLocal 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public userMealsUserMealSubmissionsGet(startDateLocal?: string, endDateLocal?: string, observe?: 'body', reportProgress?: boolean): Observable<GetMealSubmissionQueryResult>;
    public userMealsUserMealSubmissionsGet(startDateLocal?: string, endDateLocal?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetMealSubmissionQueryResult>>;
    public userMealsUserMealSubmissionsGet(startDateLocal?: string, endDateLocal?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetMealSubmissionQueryResult>>;
    public userMealsUserMealSubmissionsGet(startDateLocal?: string, endDateLocal?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDateLocal !== undefined && startDateLocal !== null) {
            queryParameters = queryParameters.set('StartDateLocal', <any>startDateLocal);
        }
        if (endDateLocal !== undefined && endDateLocal !== null) {
            queryParameters = queryParameters.set('EndDateLocal', <any>endDateLocal);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetMealSubmissionQueryResult>('get',`${this.basePath}/UserMeals/UserMealSubmissions`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
