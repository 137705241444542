import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@jsverse/transloco';
@Pipe({
  name: 'equipments',
  standalone: true,
})
export class EquipmentsPipe implements PipeTransform {
  transform(equipments: string[], activeLang?: string) {
    if (!equipments) return null;
    return translate(equipments, {}, activeLang);
  }
}
