import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customTitlecase',
  standalone: true,
})
export class CustomTitlecasePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    return value
      .split(' ')
      .map((word) => {
        if (word === 'kg' || word === 'cm' || word === 'm') {
          return word.toLowerCase();
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(' ');
  }
}
