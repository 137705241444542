import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'workoutTimeFormat',
  standalone: true,
})
export class WorkoutTimeFormatPipe implements PipeTransform {
  transform(value: string | undefined) {
    if (!value) return;
    const timeArr: string[] = value.split('-');
    let timeMin: number | string = this.parseTimeString(timeArr[0]);
    let timeMax: number | string = isNaN(parseInt(timeArr[1]))
      ? ''
      : this.parseTimeString(timeArr[1]);

    if (typeof timeMin === 'number') {
      timeMin = this.formatTime(timeMin);
    } else {
      timeMin = timeMin + 's';
    }

    if (typeof timeMax === 'number') {
      timeMax = this.formatTime(timeMax);
    } else {
      timeMax = timeMax === '' ? '' : timeMax + 's';
    }

    return timeMax === '' ? timeMin : timeMin + '-' + timeMax;
  }

  private formatTime(totalSeconds: number): string {
    const hours: number = Math.floor(totalSeconds / 3600);
    const minutes: number = Math.floor((totalSeconds % 3600) / 60);
    const seconds: number = totalSeconds % 60;

    if (totalSeconds >= 0 && totalSeconds <= 90) return `${totalSeconds}s`;

    const hoursFormat: string = hours > 0 ? `${hours}h` : '';
    const minutesFormat: string = minutes > 0 ? `${minutes}m` : '';
    const secondsFormat: string = seconds > 0 ? `${seconds}s` : '';

    return `${hoursFormat}${minutesFormat}${secondsFormat}`;
  }

  private parseTimeString(timeStr: string): number | string {
    const timeParts: string[] = timeStr.split(':');
    if (timeParts.length === 3) {
      const hours: number = parseInt(timeParts[0]);
      const minutes: number = parseInt(timeParts[1]);
      const seconds: number = parseInt(timeParts[2]);
      return hours * 3600 + minutes * 60 + seconds;
    } else if (timeParts.length === 2) {
      const minutes: number = parseInt(timeParts[0]);
      const seconds: number = parseInt(timeParts[1]);
      return minutes * 60 + seconds;
    } else {
      return parseInt(timeStr);
    }
  }
}
