import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mapIndexToLetter',
  standalone: true,
})
export class MapIndexToLetterPipe implements PipeTransform {
  transform(index: number | undefined) {
    if (!index) return '';

    const letters = ['a', 'b', 'c', 'd'];

    if (index >= 1 && index <= letters.length) {
      return letters[index - 1];
    }

    return '';
  }
}
