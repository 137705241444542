import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  concatMap,
  finalize,
  of,
  shareReplay,
  tap,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private _isLoading = new BehaviorSubject<boolean>(false);
  isLoading$ = this._isLoading.asObservable().pipe(shareReplay());
  private _isWaiting = new BehaviorSubject<string>('');
  isWaiting$ = this._isWaiting.asObservable().pipe(shareReplay());

  setLoading(isLoading: boolean) {
    this._isLoading.next(isLoading);
  }

  setWaiting(waiting: string) {
    this._isWaiting.next(waiting);
  }

  get isLoadingActive() {
    return this._isLoading.value;
  }

  showWaitingIndicator<T>(obs$: Observable<T>): Observable<T> {
    return of(null).pipe(
      tap(() => {
        this._isWaiting.next('start');
      }),
      concatMap(() => obs$),
      finalize(() => {
        this._isWaiting.next('finish');
      })
    );
  }

  showLoadingIndicator<T>(obs$: Observable<T>): Observable<T> {
    return of(null).pipe(
      tap(() => {
        this._isLoading.next(true);
      }),
      concatMap(() => obs$),
      finalize(() => {
        this._isLoading.next(false);
      })
    );
  }
}
