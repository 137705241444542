<ng-container *transloco="let t">
  <ng-container [ngSwitch]="systemMessageParams?.type">
    <ng-container *ngIf="isShortenText; else fullMessage">
      <p class="truncate-text" *ngSwitchCase="'create'" [style.color]="color">
        {{
          t('system.message.created', {
            firstName: systemMessageParams.firstName,
            lastName: systemMessageParams.lastName,
            groupName: systemMessageParams.groupName,
          }) | shortenText: 20
        }}
      </p>
      <p class="truncate-text" *ngSwitchCase="'remove'" [style.color]="color">
        {{
          t('system.message.remove', {
            adminFirstName: systemMessageParams.adminFirstName,
            adminLastName: systemMessageParams.adminLastName,
            firstName: systemMessageParams.firstName,
            lastName: systemMessageParams.lastName,
          }) | shortenText: 20
        }}
      </p>
      <p class="truncate-text" *ngSwitchCase="'left'" [style.color]="color">
        {{
          t('system.message.left', {
            firstName: systemMessageParams.firstName,
            lastName: systemMessageParams.lastName,
          }) | shortenText: 20
        }}
      </p>
      <p class="truncate-text" *ngSwitchCase="'join'" [style.color]="color">
        {{
          t('system.message.joined', {
            firstName: systemMessageParams.firstName,
            lastName: systemMessageParams.lastName,
          }) | shortenText: 20
        }}
      </p>
      <p
        class="truncate-text"
        *ngSwitchCase="'coachCreatedChat'"
        [style.color]="color"
      >
        {{
          t('system.message.coachCreatedChat', {
            firstName: systemMessageParams.firstName,
            lastName: systemMessageParams.lastName,
            coachFirstName: systemMessageParams.coachFirstName,
            coachLastName: systemMessageParams.coachLastName,
          }) | shortenText: 20
        }}
      </p>

      <p class="truncate-text" *ngSwitchCase="'newAdmin'" [style.color]="color">
        {{
          t('system.message.newAdmin', {
            newAdminFirstName: systemMessageParams.newAdminFirstName,
            newAdminLastName: systemMessageParams.newAdminLastName,
          }) | shortenText: 20
        }}
      </p>
    </ng-container>
    <ng-template #fullMessage>
      <p class="truncate-text" *ngSwitchCase="'create'" [style.color]="color">
        {{
          t('system.message.created', {
            firstName: systemMessageParams.firstName,
            lastName: systemMessageParams.lastName,
            groupName: systemMessageParams.groupName,
          })
        }}
      </p>
      <p class="truncate-text" *ngSwitchCase="'remove'" [style.color]="color">
        {{
          t('system.message.remove', {
            adminFirstName: systemMessageParams.adminFirstName,
            adminLastName: systemMessageParams.adminLastName,
            firstName: systemMessageParams.firstName,
            lastName: systemMessageParams.lastName,
          })
        }}
      </p>
      <p class="truncate-text" *ngSwitchCase="'left'" [style.color]="color">
        {{
          t('system.message.left', {
            firstName: systemMessageParams.firstName,
            lastName: systemMessageParams.lastName,
          })
        }}
      </p>
      <p class="truncate-text" *ngSwitchCase="'join'" [style.color]="color">
        {{
          t('system.message.joined', {
            firstName: systemMessageParams.firstName,
            lastName: systemMessageParams.lastName,
          })
        }}
      </p>
      <p
        class="truncate-text"
        *ngSwitchCase="'coachCreatedChat'"
        [style.color]="color"
      >
        {{
          t('system.message.coachCreatedChat', {
            firstName: systemMessageParams.firstName,
            lastName: systemMessageParams.lastName,
            coachFirstName: systemMessageParams.coachFirstName,
            coachLastName: systemMessageParams.coachLastName,
          })
        }}
      </p>

      <p class="truncate-text" *ngSwitchCase="'newAdmin'" [style.color]="color">
        {{
          t('system.message.newAdmin', {
            newAdminFirstName: systemMessageParams.newAdminFirstName,
            newAdminLastName: systemMessageParams.newAdminLastName,
          })
        }}
      </p>
    </ng-template>
  </ng-container>
</ng-container>
