/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateWorkoutCommand } from '../model/createWorkoutCommand';
import { CreateWorkoutCommandResult } from '../model/createWorkoutCommandResult';
import { DeleteWorkoutActivityCommand } from '../model/deleteWorkoutActivityCommand';
import { DeleteWorkoutCommand } from '../model/deleteWorkoutCommand';
import { DeleteWorkoutCommandResult } from '../model/deleteWorkoutCommandResult';
import { GetSingleFreemiumWorkoutQueryResult } from '../model/getSingleFreemiumWorkoutQueryResult';
import { GetSingleWorkoutDayQueryResult } from '../model/getSingleWorkoutDayQueryResult';
import { GetUserWorkoutQueryResult } from '../model/getUserWorkoutQueryResult';
import { GetWorkoutsQueryResult } from '../model/getWorkoutsQueryResult';
import { InlineResponse404 } from '../model/inlineResponse404';
import { UpdateWorkoutActivityAttributesCommand } from '../model/updateWorkoutActivityAttributesCommand';
import { UpdateWorkoutActivityOrdinalsCommand } from '../model/updateWorkoutActivityOrdinalsCommand';
import { UpdateWorkoutCommand } from '../model/updateWorkoutCommand';
import { UpdateWorkoutVisibilityCommand } from '../model/updateWorkoutVisibilityCommand';
import { WorkoutType } from '../model/workoutType';
import { WorkoutVisibilityType } from '../model/workoutVisibilityType';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class WorkoutsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * [PORTAL] Updates activity in workout
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workoutsAttributesPut(body?: UpdateWorkoutActivityAttributesCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public workoutsAttributesPut(body?: UpdateWorkoutActivityAttributesCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public workoutsAttributesPut(body?: UpdateWorkoutActivityAttributesCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public workoutsAttributesPut(body?: UpdateWorkoutActivityAttributesCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Workouts/Attributes`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Deletes workout
     * UserUid deletes WorkoutUid
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workoutsDelete(body?: DeleteWorkoutCommand, observe?: 'body', reportProgress?: boolean): Observable<DeleteWorkoutCommandResult>;
    public workoutsDelete(body?: DeleteWorkoutCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeleteWorkoutCommandResult>>;
    public workoutsDelete(body?: DeleteWorkoutCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeleteWorkoutCommandResult>>;
    public workoutsDelete(body?: DeleteWorkoutCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DeleteWorkoutCommandResult>('delete',`${this.basePath}/Workouts`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [MOBILE] Client gets single daily workout
     * 
     * @param workoutDateTimeLocal 
     * @param workoutUid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workoutsFreemiumWorkoutGet(workoutDateTimeLocal?: string, workoutUid?: string, observe?: 'body', reportProgress?: boolean): Observable<GetSingleFreemiumWorkoutQueryResult>;
    public workoutsFreemiumWorkoutGet(workoutDateTimeLocal?: string, workoutUid?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetSingleFreemiumWorkoutQueryResult>>;
    public workoutsFreemiumWorkoutGet(workoutDateTimeLocal?: string, workoutUid?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetSingleFreemiumWorkoutQueryResult>>;
    public workoutsFreemiumWorkoutGet(workoutDateTimeLocal?: string, workoutUid?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workoutDateTimeLocal !== undefined && workoutDateTimeLocal !== null) {
            queryParameters = queryParameters.set('WorkoutDateTimeLocal', <any>workoutDateTimeLocal);
        }
        if (workoutUid !== undefined && workoutUid !== null) {
            queryParameters = queryParameters.set('WorkoutUid', <any>workoutUid);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetSingleFreemiumWorkoutQueryResult>('get',`${this.basePath}/Workouts/FreemiumWorkout`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL]-[MOBILE] Gets list of workouts
     * UserUid gets list of workouts
     * @param visibiltyType 
     * @param filterTypes 
     * @param filterCategoryUids 
     * @param filterCreatedByUserUids 
     * @param bundleUid 
     * @param pageNumber 
     * @param pageSize 
     * @param searchText 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workoutsGet(visibiltyType?: WorkoutVisibilityType, filterTypes?: Array<WorkoutType>, filterCategoryUids?: Array<string>, filterCreatedByUserUids?: Array<string>, bundleUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'body', reportProgress?: boolean): Observable<GetWorkoutsQueryResult>;
    public workoutsGet(visibiltyType?: WorkoutVisibilityType, filterTypes?: Array<WorkoutType>, filterCategoryUids?: Array<string>, filterCreatedByUserUids?: Array<string>, bundleUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetWorkoutsQueryResult>>;
    public workoutsGet(visibiltyType?: WorkoutVisibilityType, filterTypes?: Array<WorkoutType>, filterCategoryUids?: Array<string>, filterCreatedByUserUids?: Array<string>, bundleUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetWorkoutsQueryResult>>;
    public workoutsGet(visibiltyType?: WorkoutVisibilityType, filterTypes?: Array<WorkoutType>, filterCategoryUids?: Array<string>, filterCreatedByUserUids?: Array<string>, bundleUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (visibiltyType !== undefined && visibiltyType !== null) {
            queryParameters = queryParameters.set('VisibiltyType', <any>visibiltyType);
        }
        if (filterTypes) {
            filterTypes.forEach((element) => {
                queryParameters = queryParameters.append('Filter.Types', <any>element);
            })
        }
        if (filterCategoryUids) {
            filterCategoryUids.forEach((element) => {
                queryParameters = queryParameters.append('Filter.CategoryUids', <any>element);
            })
        }
        if (filterCreatedByUserUids) {
            filterCreatedByUserUids.forEach((element) => {
                queryParameters = queryParameters.append('Filter.CreatedByUserUids', <any>element);
            })
        }
        if (bundleUid !== undefined && bundleUid !== null) {
            queryParameters = queryParameters.set('BundleUid', <any>bundleUid);
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('PageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (searchText !== undefined && searchText !== null) {
            queryParameters = queryParameters.set('SearchText', <any>searchText);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetWorkoutsQueryResult>('get',`${this.basePath}/Workouts`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Gets single workout day of workout program
     * 
     * @param workoutUid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workoutsGetSingleDayGet(workoutUid?: string, observe?: 'body', reportProgress?: boolean): Observable<GetSingleWorkoutDayQueryResult>;
    public workoutsGetSingleDayGet(workoutUid?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetSingleWorkoutDayQueryResult>>;
    public workoutsGetSingleDayGet(workoutUid?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetSingleWorkoutDayQueryResult>>;
    public workoutsGetSingleDayGet(workoutUid?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workoutUid !== undefined && workoutUid !== null) {
            queryParameters = queryParameters.set('WorkoutUid', <any>workoutUid);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetSingleWorkoutDayQueryResult>('get',`${this.basePath}/Workouts/GetSingleDay`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL]-[MOBILE] Gets single workout
     * UserUid get single workout
     * @param workoutUid 
     * @param isPreview 
     * @param programUid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workoutsGetSingleGet(workoutUid?: string, isPreview?: boolean, programUid?: string, observe?: 'body', reportProgress?: boolean): Observable<GetUserWorkoutQueryResult>;
    public workoutsGetSingleGet(workoutUid?: string, isPreview?: boolean, programUid?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserWorkoutQueryResult>>;
    public workoutsGetSingleGet(workoutUid?: string, isPreview?: boolean, programUid?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserWorkoutQueryResult>>;
    public workoutsGetSingleGet(workoutUid?: string, isPreview?: boolean, programUid?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (workoutUid !== undefined && workoutUid !== null) {
            queryParameters = queryParameters.set('WorkoutUid', <any>workoutUid);
        }
        if (isPreview !== undefined && isPreview !== null) {
            queryParameters = queryParameters.set('IsPreview', <any>isPreview);
        }
        if (programUid !== undefined && programUid !== null) {
            queryParameters = queryParameters.set('ProgramUid', <any>programUid);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUserWorkoutQueryResult>('get',`${this.basePath}/Workouts/GetSingle`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Updates ordinal of activities in workout
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workoutsOrdinalReorderPut(body?: UpdateWorkoutActivityOrdinalsCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public workoutsOrdinalReorderPut(body?: UpdateWorkoutActivityOrdinalsCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public workoutsOrdinalReorderPut(body?: UpdateWorkoutActivityOrdinalsCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public workoutsOrdinalReorderPut(body?: UpdateWorkoutActivityOrdinalsCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Workouts/OrdinalReorder`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Creates workout
     * UserUid creates WorkoutUid
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workoutsPost(body?: CreateWorkoutCommand, observe?: 'body', reportProgress?: boolean): Observable<CreateWorkoutCommandResult>;
    public workoutsPost(body?: CreateWorkoutCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateWorkoutCommandResult>>;
    public workoutsPost(body?: CreateWorkoutCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateWorkoutCommandResult>>;
    public workoutsPost(body?: CreateWorkoutCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreateWorkoutCommandResult>('post',`${this.basePath}/Workouts`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Updates library visibility of workout
     * 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workoutsUidIsUsedForLibraryPatch(uid: string, body?: UpdateWorkoutVisibilityCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public workoutsUidIsUsedForLibraryPatch(uid: string, body?: UpdateWorkoutVisibilityCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public workoutsUidIsUsedForLibraryPatch(uid: string, body?: UpdateWorkoutVisibilityCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public workoutsUidIsUsedForLibraryPatch(uid: string, body?: UpdateWorkoutVisibilityCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling workoutsUidIsUsedForLibraryPatch.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('patch',`${this.basePath}/Workouts/${encodeURIComponent(String(uid))}/IsUsedForLibrary`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Gets single workout
     * 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workoutsUidPut(uid: string, body?: UpdateWorkoutCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public workoutsUidPut(uid: string, body?: UpdateWorkoutCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public workoutsUidPut(uid: string, body?: UpdateWorkoutCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public workoutsUidPut(uid: string, body?: UpdateWorkoutCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling workoutsUidPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Workouts/${encodeURIComponent(String(uid))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Deletes activity of workout
     * 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public workoutsWorkoutActivityUidDelete(uid: string, body?: DeleteWorkoutActivityCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public workoutsWorkoutActivityUidDelete(uid: string, body?: DeleteWorkoutActivityCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public workoutsWorkoutActivityUidDelete(uid: string, body?: DeleteWorkoutActivityCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public workoutsWorkoutActivityUidDelete(uid: string, body?: DeleteWorkoutActivityCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling workoutsWorkoutActivityUidDelete.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('delete',`${this.basePath}/Workouts/WorkoutActivity/${encodeURIComponent(String(uid))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
