import {
  Directive,
  ElementRef,
  OnInit,
  OnDestroy,
  inject,
  output,
  input,
  signal,
} from '@angular/core';

@Directive({
  selector: '[suppressEvents]', // Use a unique selector for the directive
  standalone: true,
})
export class SuppressEventsDirective implements OnInit, OnDestroy {
  private element = inject(ElementRef);

  suppressEvents = input.required<string | string[]>();
  onClick = output<Event>();

  private eventTypes = signal<string[]>([]);

  ngOnInit() {
    this.setupEvents();
  }

  ngOnDestroy() {
    this.removeEventListeners();
  }

  private setupEvents() {
    // Normalize the input into an array of event types
    const suppressEvents = this.suppressEvents();

    if (suppressEvents === 'all') {
      this.eventTypes.set([
        'click',
        'mousedown',
        'touchstart',
        'touchmove',
        'touchend',
      ]);
    } else if (typeof suppressEvents === 'string') {
      this.eventTypes.set([suppressEvents]);
    } else {
      this.eventTypes.set(suppressEvents);
    }

    // Add listeners for each specified event type
    this.eventTypes().forEach((eventType) => {
      this.element.nativeElement.addEventListener(
        eventType,
        this.stopEvent,
        true,
      );
    });

    // Always listen to end events to emit onClick
    this.element.nativeElement.addEventListener(
      'touchend',
      this.emitOnClick,
      true,
    );
    this.element.nativeElement.addEventListener(
      'mouseup',
      this.emitOnClick,
      true,
    );
  }

  private removeEventListeners() {
    this.eventTypes().forEach((eventType) => {
      this.element.nativeElement.removeEventListener(
        eventType,
        this.stopEvent,
        true,
      );
    });
    this.element.nativeElement.removeEventListener(
      'touchend',
      this.emitOnClick,
      true,
    );
    this.element.nativeElement.removeEventListener(
      'mouseup',
      this.emitOnClick,
      true,
    );
  }

  private stopEvent = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  private emitOnClick = (event: Event) => {
    this.onClick.emit(event);
  };
}
