import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  UntypedFormGroup,
} from '@angular/forms';
import { BaseComponent } from './base.component';
import { isEqual } from 'lodash-es';

@Component({
  template: '',
})
export abstract class FormBaseComponent<
    TData extends {
      [K in keyof TData]: AbstractControl<any>;
    } = any,
  >
  extends BaseComponent
  implements OnInit, OnDestroy
{
  private destroyRef = inject(DestroyRef);
  fb = inject(FormBuilder);
  showValidation = false;
  form!: FormGroup<TData>;
  isLoading$ = this.loadingService.isLoading$;

  ngOnInit() {
    this.form?.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.cd.markForCheck());
  }

  override ngOnDestroy() {
    super.ngOnDestroy();
  }

  getName(control: AbstractControl): string | null {
    const group = control?.parent as UntypedFormGroup;
    if (!group) {
      return null;
    }
    let name = '';
    Object.keys(group.controls).forEach((key) => {
      const childControl = group.get(key);
      if (childControl !== control) {
        return;
      }
      name = key;
    });
    return name;
  }

  protected mapControlsToModel<TCommand extends object>(): TCommand {
    return this.form.value as TCommand;
  }

  protected checkFormValidity(): boolean {
    if (this.form.invalid) {
      this.showValidation = true;
      this.form.markAllAsTouched();
      this.loadingService.setWaiting('finish');
      this.cd.markForCheck();
      return true;
    }
    return false;
  }

  areAllFormValuesDefault(defaultValues: Record<string, any>): boolean {
    return Object.entries(defaultValues).every(([field, defaultValue]) => {
      const formValue = this.form.get(field)?.value ?? null;
      return isEqual(formValue, defaultValue);
    });
  }
}
