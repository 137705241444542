import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'localChatTime',
  standalone: true,
})
export class LocalChatTimePipe implements PipeTransform {
  transform(createdDateTimeUTC: string, timeZone: string, language: string) {
    const currentTimeInUserTimeZone = DateTime.fromISO(
      createdDateTimeUTC as string,
      {
        zone: timeZone,
      },
    ).setLocale(language);

    const formattedTime = currentTimeInUserTimeZone.toLocaleString(
      DateTime.TIME_SIMPLE,
    );

    return formattedTime;
  }
}
