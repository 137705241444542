import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

@Pipe({
  name: 'planes',
  standalone: true,
})
export class PlanesPipe implements PipeTransform {
  constructor(private translocoService: TranslocoService) {}

  transform(planes: string[], activeLang?: string) {
    if (!planes) return null;
    return this.translocoService.translate(planes, {}, activeLang);
  }
}
