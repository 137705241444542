/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateProgramCommand } from '../model/createProgramCommand';
import { CreateProgramCommandResult } from '../model/createProgramCommandResult';
import { CreateProgramWorkoutCommand } from '../model/createProgramWorkoutCommand';
import { DeleteProgramCommand } from '../model/deleteProgramCommand';
import { DeleteProgramCommandResult } from '../model/deleteProgramCommandResult';
import { DeleteProgramWorkoutCommand } from '../model/deleteProgramWorkoutCommand';
import { GetProgramsQueryResult } from '../model/getProgramsQueryResult';
import { GetSingleProgramQueryResult } from '../model/getSingleProgramQueryResult';
import { InlineResponse404 } from '../model/inlineResponse404';
import { ProgramType } from '../model/programType';
import { UpdateProgramCommand } from '../model/updateProgramCommand';
import { UpdateProgramScheduleCommand } from '../model/updateProgramScheduleCommand';
import { UpdateProgramWorkoutCommand } from '../model/updateProgramWorkoutCommand';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ProgramService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Creates program workout/workouts for logged selected program
     * UserUid Creates program workout with WorkoutUid with number of repetitions and stopAfter.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programCreateProgramWorkoutPost(body?: CreateProgramWorkoutCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public programCreateProgramWorkoutPost(body?: CreateProgramWorkoutCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public programCreateProgramWorkoutPost(body?: CreateProgramWorkoutCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public programCreateProgramWorkoutPost(body?: CreateProgramWorkoutCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/Program/CreateProgramWorkout`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes program
     * UserUid deletes ProgramUid
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programDelete(body?: DeleteProgramCommand, observe?: 'body', reportProgress?: boolean): Observable<DeleteProgramCommandResult>;
    public programDelete(body?: DeleteProgramCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeleteProgramCommandResult>>;
    public programDelete(body?: DeleteProgramCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeleteProgramCommandResult>>;
    public programDelete(body?: DeleteProgramCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DeleteProgramCommandResult>('delete',`${this.basePath}/Program`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a workout from program
     * UserUid deletes ProgramWorkoutUid
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programDeleteProgramWorkoutDelete(body?: DeleteProgramWorkoutCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public programDeleteProgramWorkoutDelete(body?: DeleteProgramWorkoutCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public programDeleteProgramWorkoutDelete(body?: DeleteProgramWorkoutCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public programDeleteProgramWorkoutDelete(body?: DeleteProgramWorkoutCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('delete',`${this.basePath}/Program/DeleteProgramWorkout`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [COACH] Gets programs list 
     * UserUid gets program&#x27;s list of his team
     * @param filterLength 
     * @param filterCreatedByUserUids 
     * @param type 
     * @param bundleUid 
     * @param pageNumber 
     * @param pageSize 
     * @param searchText 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programGet(filterLength?: number, filterCreatedByUserUids?: Array<string>, type?: ProgramType, bundleUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'body', reportProgress?: boolean): Observable<GetProgramsQueryResult>;
    public programGet(filterLength?: number, filterCreatedByUserUids?: Array<string>, type?: ProgramType, bundleUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetProgramsQueryResult>>;
    public programGet(filterLength?: number, filterCreatedByUserUids?: Array<string>, type?: ProgramType, bundleUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetProgramsQueryResult>>;
    public programGet(filterLength?: number, filterCreatedByUserUids?: Array<string>, type?: ProgramType, bundleUid?: string, pageNumber?: number, pageSize?: number, searchText?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filterLength !== undefined && filterLength !== null) {
            queryParameters = queryParameters.set('Filter.Length', <any>filterLength);
        }
        if (filterCreatedByUserUids) {
            filterCreatedByUserUids.forEach((element) => {
                queryParameters = queryParameters.append('Filter.CreatedByUserUids', <any>element);
            })
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('Type', <any>type);
        }
        if (bundleUid !== undefined && bundleUid !== null) {
            queryParameters = queryParameters.set('BundleUid', <any>bundleUid);
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('PageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (searchText !== undefined && searchText !== null) {
            queryParameters = queryParameters.set('SearchText', <any>searchText);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetProgramsQueryResult>('get',`${this.basePath}/Program`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates program with Name and Length for logged coach
     * UserUid Creates ProgramUid with Name and Length
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programPost(body?: CreateProgramCommand, observe?: 'body', reportProgress?: boolean): Observable<CreateProgramCommandResult>;
    public programPost(body?: CreateProgramCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateProgramCommandResult>>;
    public programPost(body?: CreateProgramCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateProgramCommandResult>>;
    public programPost(body?: CreateProgramCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreateProgramCommandResult>('post',`${this.basePath}/Program`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param programUid 
     * @param dayNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programUidDayDayNumberDelete(programUid: string, dayNumber: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public programUidDayDayNumberDelete(programUid: string, dayNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public programUidDayDayNumberDelete(programUid: string, dayNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public programUidDayDayNumberDelete(programUid: string, dayNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (programUid === null || programUid === undefined) {
            throw new Error('Required parameter programUid was null or undefined when calling programUidDayDayNumberDelete.');
        }

        if (dayNumber === null || dayNumber === undefined) {
            throw new Error('Required parameter dayNumber was null or undefined when calling programUidDayDayNumberDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/${encodeURIComponent(String(programUid))}/Day/${encodeURIComponent(String(dayNumber))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param isPreview 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programUidGet(uid: string, isPreview?: boolean, observe?: 'body', reportProgress?: boolean): Observable<GetSingleProgramQueryResult>;
    public programUidGet(uid: string, isPreview?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetSingleProgramQueryResult>>;
    public programUidGet(uid: string, isPreview?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetSingleProgramQueryResult>>;
    public programUidGet(uid: string, isPreview?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling programUidGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (isPreview !== undefined && isPreview !== null) {
            queryParameters = queryParameters.set('isPreview', <any>isPreview);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetSingleProgramQueryResult>('get',`${this.basePath}/Program/${encodeURIComponent(String(uid))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programUidPut(uid: string, body?: UpdateProgramCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public programUidPut(uid: string, body?: UpdateProgramCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public programUidPut(uid: string, body?: UpdateProgramCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public programUidPut(uid: string, body?: UpdateProgramCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling programUidPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Program/${encodeURIComponent(String(uid))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Changes DayNumber and Ordinal of dragged program workout
     * UserUid reorders workouts in Workouts list.
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programUpdateProgramSchedulePut(body?: UpdateProgramScheduleCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public programUpdateProgramSchedulePut(body?: UpdateProgramScheduleCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public programUpdateProgramSchedulePut(body?: UpdateProgramScheduleCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public programUpdateProgramSchedulePut(body?: UpdateProgramScheduleCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Program/UpdateProgramSchedule`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates workout in program to be specific 
     * UserUid updates WorkoutUid with NewWorkoutUid in ProgramUid
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public programUpdateProgramWorkoutPut(body?: UpdateProgramWorkoutCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public programUpdateProgramWorkoutPut(body?: UpdateProgramWorkoutCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public programUpdateProgramWorkoutPut(body?: UpdateProgramWorkoutCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public programUpdateProgramWorkoutPut(body?: UpdateProgramWorkoutCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Program/UpdateProgramWorkout`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
