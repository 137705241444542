import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@jsverse/transloco';
import { DateTime } from 'luxon';

@Pipe({
  name: 'currentDate',
  standalone: true,
  pure: false,
})
export class CurrentDatePipe implements PipeTransform {
  transform(date: string | null | undefined, activeLang = 'en'): string {
    const currentDate = this.createDateTime('local', activeLang).startOf('day');
    if (!date) return currentDate.toISO() as string;

    const comparisonDate = this.createDateTimeFromISO(
      date,
      'local',
      activeLang,
    ).startOf('day');

    const day = this.getDayDescription(currentDate, comparisonDate, activeLang);
    const formattedDate = this.getFormattedDate(comparisonDate, activeLang);

    return day ? `${day}${formattedDate}` : formattedDate;
  }

  private createDateTime(timeZone: string, activeLang?: string): DateTime {
    return DateTime.local({ zone: timeZone, locale: activeLang || '' });
  }

  private createDateTimeFromISO(
    date: string,
    timeZone: string,
    activeLang?: string,
  ): DateTime {
    return DateTime.fromISO(date, { zone: timeZone, locale: activeLang || '' });
  }

  private getDayDescription(
    currentDate: DateTime,
    comparisonDate: DateTime,
    activeLang?: string,
  ): string | undefined {
    const dayKeys: { [key: string]: DateTime } = {
      'Today, ': currentDate,
      'Tomorrow, ': currentDate.plus({ days: 1 }),
      'Yesterday, ': currentDate.minus({ days: 1 }),
    };

    for (const [key, dateTime] of Object.entries(dayKeys)) {
      if (comparisonDate.hasSame(dateTime, 'day')) {
        return translate(key, {}, activeLang);
      }
    }

    return;
  }

  private getFormattedDate(dateTime: DateTime, activeLang: string): string {
    const formattedDayDate = dateTime.toFormat('dd');
    const formattedMonthDate = dateTime.setLocale(activeLang).toFormat('MMM');

    return `${formattedDayDate} ${formattedMonthDate}`;
  }
}
