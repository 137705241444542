/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateGroupCommand } from '../model/createGroupCommand';
import { CreateGroupCommandResult } from '../model/createGroupCommandResult';
import { GetGroupQueryResult } from '../model/getGroupQueryResult';
import { GetGroupsQueryResult } from '../model/getGroupsQueryResult';
import { GroupType } from '../model/groupType';
import { GroupsSortSpecification } from '../model/groupsSortSpecification';
import { InlineResponse404 } from '../model/inlineResponse404';
import { LeaveGroupCommand } from '../model/leaveGroupCommand';
import { RemoveFromGroupCommand } from '../model/removeFromGroupCommand';
import { UpdateGroupAdminCommand } from '../model/updateGroupAdminCommand';
import { UpdateGroupCommand } from '../model/updateGroupCommand';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class GroupsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get all users&#x27; group 
     * UserUid gets all his groups
     * @param clientUid 
     * @param hasPendingMessages 
     * @param type 
     * @param isUsedForStatistics 
     * @param organizationUid 
     * @param teamUid 
     * @param sort 
     * @param pageNumber 
     * @param pageSize 
     * @param searchText 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupsGroupsGet(clientUid?: string, hasPendingMessages?: boolean, type?: GroupType, isUsedForStatistics?: boolean, organizationUid?: string, teamUid?: string, sort?: Array<GroupsSortSpecification>, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'body', reportProgress?: boolean): Observable<GetGroupsQueryResult>;
    public groupsGroupsGet(clientUid?: string, hasPendingMessages?: boolean, type?: GroupType, isUsedForStatistics?: boolean, organizationUid?: string, teamUid?: string, sort?: Array<GroupsSortSpecification>, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetGroupsQueryResult>>;
    public groupsGroupsGet(clientUid?: string, hasPendingMessages?: boolean, type?: GroupType, isUsedForStatistics?: boolean, organizationUid?: string, teamUid?: string, sort?: Array<GroupsSortSpecification>, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetGroupsQueryResult>>;
    public groupsGroupsGet(clientUid?: string, hasPendingMessages?: boolean, type?: GroupType, isUsedForStatistics?: boolean, organizationUid?: string, teamUid?: string, sort?: Array<GroupsSortSpecification>, pageNumber?: number, pageSize?: number, searchText?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {











        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (clientUid !== undefined && clientUid !== null) {
            queryParameters = queryParameters.set('ClientUid', <any>clientUid);
        }
        if (hasPendingMessages !== undefined && hasPendingMessages !== null) {
            queryParameters = queryParameters.set('HasPendingMessages', <any>hasPendingMessages);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('Type', <any>type);
        }
        if (isUsedForStatistics !== undefined && isUsedForStatistics !== null) {
            queryParameters = queryParameters.set('IsUsedForStatistics', <any>isUsedForStatistics);
        }
        if (organizationUid !== undefined && organizationUid !== null) {
            queryParameters = queryParameters.set('OrganizationUid', <any>organizationUid);
        }
        if (teamUid !== undefined && teamUid !== null) {
            queryParameters = queryParameters.set('TeamUid', <any>teamUid);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('Sort', <any>element);
            })
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('PageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (searchText !== undefined && searchText !== null) {
            queryParameters = queryParameters.set('SearchText', <any>searchText);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetGroupsQueryResult>('get',`${this.basePath}/Groups/Groups`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Current user leaving group
     * UserUid leave GroupUid
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupsLeaveGroupDelete(body?: LeaveGroupCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public groupsLeaveGroupDelete(body?: LeaveGroupCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public groupsLeaveGroupDelete(body?: LeaveGroupCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public groupsLeaveGroupDelete(body?: LeaveGroupCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('delete',`${this.basePath}/Groups/LeaveGroup`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates new group with given groupName
     * UserUid Creates GroupUid with GroupName and list of InvitationEmails. Calls UpdateMembersHandler
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupsPost(body?: CreateGroupCommand, observe?: 'body', reportProgress?: boolean): Observable<CreateGroupCommandResult>;
    public groupsPost(body?: CreateGroupCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateGroupCommandResult>>;
    public groupsPost(body?: CreateGroupCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateGroupCommandResult>>;
    public groupsPost(body?: CreateGroupCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreateGroupCommandResult>('post',`${this.basePath}/Groups`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove user from group
     * UserUid kicks KickedUid from GroupUid
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupsRemoveFromGroupDelete(body?: RemoveFromGroupCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public groupsRemoveFromGroupDelete(body?: RemoveFromGroupCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public groupsRemoveFromGroupDelete(body?: RemoveFromGroupCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public groupsRemoveFromGroupDelete(body?: RemoveFromGroupCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('delete',`${this.basePath}/Groups/RemoveFromGroup`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Deletes a group
     * UserUid deletes GroupUid
     * @param uid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupsUidDelete(uid: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public groupsUidDelete(uid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public groupsUidDelete(uid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public groupsUidDelete(uid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling groupsUidDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/Groups/${encodeURIComponent(String(uid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get single group with users
     * UserUid gets GroupUid 
     * @param uid 
     * @param pageNumber 
     * @param pageSize 
     * @param searchText 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupsUidGet(uid: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'body', reportProgress?: boolean): Observable<GetGroupQueryResult>;
    public groupsUidGet(uid: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetGroupQueryResult>>;
    public groupsUidGet(uid: string, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetGroupQueryResult>>;
    public groupsUidGet(uid: string, pageNumber?: number, pageSize?: number, searchText?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling groupsUidGet.');
        }




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('PageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (searchText !== undefined && searchText !== null) {
            queryParameters = queryParameters.set('SearchText', <any>searchText);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetGroupQueryResult>('get',`${this.basePath}/Groups/${encodeURIComponent(String(uid))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param groupUids 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupsUnreadCountGet(groupUids?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public groupsUnreadCountGet(groupUids?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public groupsUnreadCountGet(groupUids?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public groupsUnreadCountGet(groupUids?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (groupUids) {
            groupUids.forEach((element) => {
                queryParameters = queryParameters.append('GroupUids', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<number>('get',`${this.basePath}/Groups/UnreadCount`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupsUpdateAdminPut(body?: UpdateGroupAdminCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public groupsUpdateAdminPut(body?: UpdateGroupAdminCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public groupsUpdateAdminPut(body?: UpdateGroupAdminCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public groupsUpdateAdminPut(body?: UpdateGroupAdminCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Groups/UpdateAdmin`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates groupName and list of users
     * UserUid update GroupUid with GroupName and list of InvitationEmails
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public groupsUpdateGroupPut(body?: UpdateGroupCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public groupsUpdateGroupPut(body?: UpdateGroupCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public groupsUpdateGroupPut(body?: UpdateGroupCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public groupsUpdateGroupPut(body?: UpdateGroupCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Groups/UpdateGroup`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
