import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'floor',
  standalone: true,
})
export class FloorPipe implements PipeTransform {
  transform(value: number | undefined) {
    if (!value) return 0;
    const flooredValue = Math.floor(value);
    return flooredValue;
  }
}
