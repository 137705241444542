/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type RoleType = 'Unknown' | 'Coach' | 'CustomClient' | 'PremiumClient' | 'System' | 'FreemiumClient' | 'OrganizationAdmin' | 'SuperAdmin' | 'Translator';

export const RoleType = {
    Unknown: 'Unknown' as RoleType,
    Coach: 'Coach' as RoleType,
    CustomClient: 'CustomClient' as RoleType,
    PremiumClient: 'PremiumClient' as RoleType,
    System: 'System' as RoleType,
    FreemiumClient: 'FreemiumClient' as RoleType,
    OrganizationAdmin: 'OrganizationAdmin' as RoleType,
    SuperAdmin: 'SuperAdmin' as RoleType,
    Translator: 'Translator' as RoleType
};