import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'breakRow',
  standalone: true,
})
export class BreakRowPipe implements PipeTransform {
  transform(value: string | null | undefined): string {
    if (!value) return '';

    return value.replace(/\n/g, '<br/>');
  }
}
