/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateHabitEventCommand } from '../model/createHabitEventCommand';
import { CreateHabitEventCommandResult } from '../model/createHabitEventCommandResult';
import { DeleteHabitEventCommandResult } from '../model/deleteHabitEventCommandResult';
import { GetHabitEventQueryResult } from '../model/getHabitEventQueryResult';
import { GetHabitEventStatisticsQueryResult } from '../model/getHabitEventStatisticsQueryResult';
import { GetHabitEventsQueryResult } from '../model/getHabitEventsQueryResult';
import { InlineResponse404 } from '../model/inlineResponse404';
import { UpdateHabitEventCommand } from '../model/updateHabitEventCommand';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class HabitEventsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param startDateTimeUTC 
     * @param endDateTimeUTC 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public habitEventsGet(startDateTimeUTC?: string, endDateTimeUTC?: string, observe?: 'body', reportProgress?: boolean): Observable<GetHabitEventsQueryResult>;
    public habitEventsGet(startDateTimeUTC?: string, endDateTimeUTC?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetHabitEventsQueryResult>>;
    public habitEventsGet(startDateTimeUTC?: string, endDateTimeUTC?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetHabitEventsQueryResult>>;
    public habitEventsGet(startDateTimeUTC?: string, endDateTimeUTC?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDateTimeUTC !== undefined && startDateTimeUTC !== null) {
            queryParameters = queryParameters.set('StartDateTimeUTC', <any>startDateTimeUTC);
        }
        if (endDateTimeUTC !== undefined && endDateTimeUTC !== null) {
            queryParameters = queryParameters.set('EndDateTimeUTC', <any>endDateTimeUTC);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetHabitEventsQueryResult>('get',`${this.basePath}/HabitEvents`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public habitEventsPost(body?: CreateHabitEventCommand, observe?: 'body', reportProgress?: boolean): Observable<CreateHabitEventCommandResult>;
    public habitEventsPost(body?: CreateHabitEventCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateHabitEventCommandResult>>;
    public habitEventsPost(body?: CreateHabitEventCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateHabitEventCommandResult>>;
    public habitEventsPost(body?: CreateHabitEventCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreateHabitEventCommandResult>('post',`${this.basePath}/HabitEvents`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param startDateUTC 
     * @param endDateUTC 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public habitEventsStatisticsUidGet(uid: string, startDateUTC?: string, endDateUTC?: string, observe?: 'body', reportProgress?: boolean): Observable<GetHabitEventStatisticsQueryResult>;
    public habitEventsStatisticsUidGet(uid: string, startDateUTC?: string, endDateUTC?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetHabitEventStatisticsQueryResult>>;
    public habitEventsStatisticsUidGet(uid: string, startDateUTC?: string, endDateUTC?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetHabitEventStatisticsQueryResult>>;
    public habitEventsStatisticsUidGet(uid: string, startDateUTC?: string, endDateUTC?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling habitEventsStatisticsUidGet.');
        }



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (startDateUTC !== undefined && startDateUTC !== null) {
            queryParameters = queryParameters.set('StartDateUTC', <any>startDateUTC);
        }
        if (endDateUTC !== undefined && endDateUTC !== null) {
            queryParameters = queryParameters.set('EndDateUTC', <any>endDateUTC);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetHabitEventStatisticsQueryResult>('get',`${this.basePath}/HabitEvents/Statistics/${encodeURIComponent(String(uid))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public habitEventsUidDelete(uid: string, observe?: 'body', reportProgress?: boolean): Observable<DeleteHabitEventCommandResult>;
    public habitEventsUidDelete(uid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeleteHabitEventCommandResult>>;
    public habitEventsUidDelete(uid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeleteHabitEventCommandResult>>;
    public habitEventsUidDelete(uid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling habitEventsUidDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DeleteHabitEventCommandResult>('delete',`${this.basePath}/HabitEvents/${encodeURIComponent(String(uid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public habitEventsUidGet(uid: string, observe?: 'body', reportProgress?: boolean): Observable<GetHabitEventQueryResult>;
    public habitEventsUidGet(uid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetHabitEventQueryResult>>;
    public habitEventsUidGet(uid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetHabitEventQueryResult>>;
    public habitEventsUidGet(uid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling habitEventsUidGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetHabitEventQueryResult>('get',`${this.basePath}/HabitEvents/${encodeURIComponent(String(uid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public habitEventsUidPut(uid: string, body?: UpdateHabitEventCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public habitEventsUidPut(uid: string, body?: UpdateHabitEventCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public habitEventsUidPut(uid: string, body?: UpdateHabitEventCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public habitEventsUidPut(uid: string, body?: UpdateHabitEventCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling habitEventsUidPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/HabitEvents/${encodeURIComponent(String(uid))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
