import { Pipe, PipeTransform } from '@angular/core';
import { UserMessageWithWorkoutModel } from '../models/user-message-with-workout.model';
import { DateTime } from 'luxon';

@Pipe({
  name: 'shouldShowDate',
  standalone: true,
})
export class ShouldShowDatePipe implements PipeTransform {
  transform(
    currentMessage: UserMessageWithWorkoutModel,
    previousMessage: UserMessageWithWorkoutModel | undefined,
  ): boolean {
    if (!previousMessage || !previousMessage?.message?.createdDateTimeUTC) {
      return true;
    }

    const currentMessageDate = DateTime.fromISO(
      currentMessage.message?.createdDateTimeUTC as string,
    )
      .setZone('local')
      .startOf('day');

    const lastMessageDate = DateTime.fromISO(
      previousMessage.message?.createdDateTimeUTC,
    )
      .setZone('local')
      .startOf('day');

    return !currentMessageDate.hasSame(lastMessageDate, 'day');
  }
}
