import { Injectable } from '@angular/core';
import { IconDefinition } from '@fortawesome/pro-thin-svg-icons';
import * as thinIcons from '@fortawesome/pro-thin-svg-icons';

@Injectable({ providedIn: 'root' })
export class IconsService {
  private _allThinIcons!: IconDefinition[];

  constructor() {
    this._allThinIcons = this.extractIconsFromPack(thinIcons);
  }

  private extractIconsFromPack(pack: any): IconDefinition[] {
    return Array.from(
      new Set(
        Object.keys(pack)
          .filter((key) => pack[key].iconName) // Check if the property has an iconName, which is typical of Font Awesome icons
          .map((key) => pack[key] as IconDefinition)
      )
    );
  }

  get allIcons() {
    return this._allThinIcons;
  }
}
