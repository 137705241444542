/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AssignDeviceCommand } from '../model/assignDeviceCommand';
import { ChangeUserEmailCommand } from '../model/changeUserEmailCommand';
import { ChangeUserLanguageCommand } from '../model/changeUserLanguageCommand';
import { ChangeUserRoleCommand } from '../model/changeUserRoleCommand';
import { ChangeUserStatusCommand } from '../model/changeUserStatusCommand';
import { CheckPremiumSubscriptionQueryResult } from '../model/checkPremiumSubscriptionQueryResult';
import { CreateChangeEmailRequestCommand } from '../model/createChangeEmailRequestCommand';
import { CreateChangeEmailRequestCommandResult } from '../model/createChangeEmailRequestCommandResult';
import { CreateUserCommand } from '../model/createUserCommand';
import { CreateUserCommandResult } from '../model/createUserCommandResult';
import { GetClientsQueryResult } from '../model/getClientsQueryResult';
import { GetPriceSegmentQueryResult } from '../model/getPriceSegmentQueryResult';
import { GetUserQueryResult } from '../model/getUserQueryResult';
import { GetUserTeamQueryResult } from '../model/getUserTeamQueryResult';
import { GetUsersQueryResult } from '../model/getUsersQueryResult';
import { InlineResponse404 } from '../model/inlineResponse404';
import { SignInCommand } from '../model/signInCommand';
import { SignOutCommand } from '../model/signOutCommand';
import { UpdateUserCommand } from '../model/updateUserCommand';
import { UpdateUserCountryChangeDateCommand } from '../model/updateUserCountryChangeDateCommand';
import { UserStatusType } from '../model/userStatusType';
import { UsersSortSpecification } from '../model/usersSortSpecification';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UsersService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Change deviceId for user with provided uid
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersAssignDevicePatch(body?: AssignDeviceCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public usersAssignDevicePatch(body?: AssignDeviceCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public usersAssignDevicePatch(body?: AssignDeviceCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public usersAssignDevicePatch(body?: AssignDeviceCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('patch',`${this.basePath}/Users/AssignDevice`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change language for user with provided uid
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersChangeLanguagePatch(body?: ChangeUserLanguageCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public usersChangeLanguagePatch(body?: ChangeUserLanguageCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public usersChangeLanguagePatch(body?: ChangeUserLanguageCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public usersChangeLanguagePatch(body?: ChangeUserLanguageCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('patch',`${this.basePath}/Users/ChangeLanguage`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change role for user with provided uid
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersChangeRolePut(body?: ChangeUserRoleCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public usersChangeRolePut(body?: ChangeUserRoleCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public usersChangeRolePut(body?: ChangeUserRoleCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public usersChangeRolePut(body?: ChangeUserRoleCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Users/ChangeRole`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersCheckSubscriptionGet(observe?: 'body', reportProgress?: boolean): Observable<CheckPremiumSubscriptionQueryResult>;
    public usersCheckSubscriptionGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CheckPremiumSubscriptionQueryResult>>;
    public usersCheckSubscriptionGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CheckPremiumSubscriptionQueryResult>>;
    public usersCheckSubscriptionGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CheckPremiumSubscriptionQueryResult>('get',`${this.basePath}/Users/CheckSubscription`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Get all coach clients 
     * UserUid gets all clients
     * @param filterTeamUid 
     * @param filterCoachUid 
     * @param filterOrganizationUid 
     * @param filterStatus 
     * @param sort 
     * @param pageNumber 
     * @param pageSize 
     * @param searchText 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersClientsGet(filterTeamUid?: string, filterCoachUid?: string, filterOrganizationUid?: string, filterStatus?: UserStatusType, sort?: Array<UsersSortSpecification>, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'body', reportProgress?: boolean): Observable<GetClientsQueryResult>;
    public usersClientsGet(filterTeamUid?: string, filterCoachUid?: string, filterOrganizationUid?: string, filterStatus?: UserStatusType, sort?: Array<UsersSortSpecification>, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetClientsQueryResult>>;
    public usersClientsGet(filterTeamUid?: string, filterCoachUid?: string, filterOrganizationUid?: string, filterStatus?: UserStatusType, sort?: Array<UsersSortSpecification>, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetClientsQueryResult>>;
    public usersClientsGet(filterTeamUid?: string, filterCoachUid?: string, filterOrganizationUid?: string, filterStatus?: UserStatusType, sort?: Array<UsersSortSpecification>, pageNumber?: number, pageSize?: number, searchText?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filterTeamUid !== undefined && filterTeamUid !== null) {
            queryParameters = queryParameters.set('Filter.TeamUid', <any>filterTeamUid);
        }
        if (filterCoachUid !== undefined && filterCoachUid !== null) {
            queryParameters = queryParameters.set('Filter.CoachUid', <any>filterCoachUid);
        }
        if (filterOrganizationUid !== undefined && filterOrganizationUid !== null) {
            queryParameters = queryParameters.set('Filter.OrganizationUid', <any>filterOrganizationUid);
        }
        if (filterStatus !== undefined && filterStatus !== null) {
            queryParameters = queryParameters.set('Filter.Status', <any>filterStatus);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('Sort', <any>element);
            })
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('PageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (searchText !== undefined && searchText !== null) {
            queryParameters = queryParameters.set('SearchText', <any>searchText);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetClientsQueryResult>('get',`${this.basePath}/Users/Clients`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param userUid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersCoachGet(userUid?: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public usersCoachGet(userUid?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public usersCoachGet(userUid?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public usersCoachGet(userUid?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userUid !== undefined && userUid !== null) {
            queryParameters = queryParameters.set('UserUid', <any>userUid);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('get',`${this.basePath}/Users/Coach`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersEmailChangeConfirmRequestPut(body?: ChangeUserEmailCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public usersEmailChangeConfirmRequestPut(body?: ChangeUserEmailCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public usersEmailChangeConfirmRequestPut(body?: ChangeUserEmailCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public usersEmailChangeConfirmRequestPut(body?: ChangeUserEmailCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Users/EmailChange/ConfirmRequest`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a request for changing email
     * UserUid Creates request for changing email 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersEmailChangeCreateRequestPost(body?: CreateChangeEmailRequestCommand, observe?: 'body', reportProgress?: boolean): Observable<CreateChangeEmailRequestCommandResult>;
    public usersEmailChangeCreateRequestPost(body?: CreateChangeEmailRequestCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateChangeEmailRequestCommandResult>>;
    public usersEmailChangeCreateRequestPost(body?: CreateChangeEmailRequestCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateChangeEmailRequestCommandResult>>;
    public usersEmailChangeCreateRequestPost(body?: CreateChangeEmailRequestCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreateChangeEmailRequestCommandResult>('post',`${this.basePath}/Users/EmailChange/CreateRequest`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Gets list of users 
     * 
     * @param teamUid 
     * @param organizationUid 
     * @param isGlobalAdminIncluded 
     * @param pageNumber 
     * @param pageSize 
     * @param searchText 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersGet(teamUid?: string, organizationUid?: string, isGlobalAdminIncluded?: boolean, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'body', reportProgress?: boolean): Observable<GetUsersQueryResult>;
    public usersGet(teamUid?: string, organizationUid?: string, isGlobalAdminIncluded?: boolean, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUsersQueryResult>>;
    public usersGet(teamUid?: string, organizationUid?: string, isGlobalAdminIncluded?: boolean, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUsersQueryResult>>;
    public usersGet(teamUid?: string, organizationUid?: string, isGlobalAdminIncluded?: boolean, pageNumber?: number, pageSize?: number, searchText?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (teamUid !== undefined && teamUid !== null) {
            queryParameters = queryParameters.set('TeamUid', <any>teamUid);
        }
        if (organizationUid !== undefined && organizationUid !== null) {
            queryParameters = queryParameters.set('OrganizationUid', <any>organizationUid);
        }
        if (isGlobalAdminIncluded !== undefined && isGlobalAdminIncluded !== null) {
            queryParameters = queryParameters.set('IsGlobalAdminIncluded', <any>isGlobalAdminIncluded);
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('PageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (searchText !== undefined && searchText !== null) {
            queryParameters = queryParameters.set('SearchText', <any>searchText);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUsersQueryResult>('get',`${this.basePath}/Users`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL] Creates/Invites new user as custom with provided information
     * UserUid Creates/Invites user with email 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersPost(body?: CreateUserCommand, observe?: 'body', reportProgress?: boolean): Observable<CreateUserCommandResult>;
    public usersPost(body?: CreateUserCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateUserCommandResult>>;
    public usersPost(body?: CreateUserCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateUserCommandResult>>;
    public usersPost(body?: CreateUserCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreateUserCommandResult>('post',`${this.basePath}/Users`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersPriceSegmentGet(observe?: 'body', reportProgress?: boolean): Observable<GetPriceSegmentQueryResult>;
    public usersPriceSegmentGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetPriceSegmentQueryResult>>;
    public usersPriceSegmentGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetPriceSegmentQueryResult>>;
    public usersPriceSegmentGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetPriceSegmentQueryResult>('get',`${this.basePath}/Users/PriceSegment`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates client with provided information
     * Updates client
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersPut(body?: UpdateUserCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public usersPut(body?: UpdateUserCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public usersPut(body?: UpdateUserCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public usersPut(body?: UpdateUserCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Users`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * [PORTAL]-[MOBILE] Syncs Core User table with Auth. Changes invite status on first login. 
     * Called after SignIn on Auth
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersSignInPost(body?: SignInCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public usersSignInPost(body?: SignInCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public usersSignInPost(body?: SignInCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public usersSignInPost(body?: SignInCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/Users/SignIn`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Only for app: Remove DeviceId on SignOut. 
     * Called after/before FE clears cookies and token
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersSignOutPost(body?: SignOutCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public usersSignOutPost(body?: SignOutCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public usersSignOutPost(body?: SignOutCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public usersSignOutPost(body?: SignOutCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/Users/SignOut`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersTeamGet(observe?: 'body', reportProgress?: boolean): Observable<GetUserTeamQueryResult>;
    public usersTeamGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserTeamQueryResult>>;
    public usersTeamGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserTeamQueryResult>>;
    public usersTeamGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUserTeamQueryResult>('get',`${this.basePath}/Users/Team`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersUidDelete(uid: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public usersUidDelete(uid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public usersUidDelete(uid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public usersUidDelete(uid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling usersUidDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/Users/${encodeURIComponent(String(uid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets single user
     * Uid gets user
     * @param uid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersUidGet(uid: string, observe?: 'body', reportProgress?: boolean): Observable<GetUserQueryResult>;
    public usersUidGet(uid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserQueryResult>>;
    public usersUidGet(uid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserQueryResult>>;
    public usersUidGet(uid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling usersUidGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUserQueryResult>('get',`${this.basePath}/Users/${encodeURIComponent(String(uid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change status for user with provided uid
     * UserUid change user status ( switching status and role) 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersUidStatusPut(uid: string, body?: ChangeUserStatusCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public usersUidStatusPut(uid: string, body?: ChangeUserStatusCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public usersUidStatusPut(uid: string, body?: ChangeUserStatusCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public usersUidStatusPut(uid: string, body?: ChangeUserStatusCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling usersUidStatusPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Users/${encodeURIComponent(String(uid))}/Status`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersUpdateCountryChangeDatePut(body?: UpdateUserCountryChangeDateCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public usersUpdateCountryChangeDatePut(body?: UpdateUserCountryChangeDateCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public usersUpdateCountryChangeDatePut(body?: UpdateUserCountryChangeDateCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public usersUpdateCountryChangeDatePut(body?: UpdateUserCountryChangeDateCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Users/UpdateCountryChangeDate`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
