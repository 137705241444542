import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[adHost]',
    standalone: true,
})
export class AdDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}
