/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ChangeCoachStatusCommand } from '../model/changeCoachStatusCommand';
import { ChangeCoachTypeCommand } from '../model/changeCoachTypeCommand';
import { CoachType } from '../model/coachType';
import { CoachesSortSpecification } from '../model/coachesSortSpecification';
import { CreateCoachCommand } from '../model/createCoachCommand';
import { GetCoachQueryResult } from '../model/getCoachQueryResult';
import { GetCoachesQueryResult } from '../model/getCoachesQueryResult';
import { InlineResponse404 } from '../model/inlineResponse404';
import { UpdateCoachCommand } from '../model/updateCoachCommand';
import { UserStatusType } from '../model/userStatusType';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CoachesService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public coachesCoachTypeGet(observe?: 'body', reportProgress?: boolean): Observable<CoachType>;
    public coachesCoachTypeGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CoachType>>;
    public coachesCoachTypeGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CoachType>>;
    public coachesCoachTypeGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CoachType>('get',`${this.basePath}/Coaches/CoachType`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all coaches in team 
     * UserUid gets all coaches in his team
     * @param status 
     * @param filterTeamUids 
     * @param filterIsAllTeamFilterApplied 
     * @param filterOrganizationUid 
     * @param sort 
     * @param areOrganizationAdminsIncluded 
     * @param pageNumber 
     * @param pageSize 
     * @param searchText 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public coachesCoachesGet(status?: UserStatusType, filterTeamUids?: Array<string>, filterIsAllTeamFilterApplied?: boolean, filterOrganizationUid?: string, sort?: Array<CoachesSortSpecification>, areOrganizationAdminsIncluded?: boolean, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'body', reportProgress?: boolean): Observable<GetCoachesQueryResult>;
    public coachesCoachesGet(status?: UserStatusType, filterTeamUids?: Array<string>, filterIsAllTeamFilterApplied?: boolean, filterOrganizationUid?: string, sort?: Array<CoachesSortSpecification>, areOrganizationAdminsIncluded?: boolean, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetCoachesQueryResult>>;
    public coachesCoachesGet(status?: UserStatusType, filterTeamUids?: Array<string>, filterIsAllTeamFilterApplied?: boolean, filterOrganizationUid?: string, sort?: Array<CoachesSortSpecification>, areOrganizationAdminsIncluded?: boolean, pageNumber?: number, pageSize?: number, searchText?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetCoachesQueryResult>>;
    public coachesCoachesGet(status?: UserStatusType, filterTeamUids?: Array<string>, filterIsAllTeamFilterApplied?: boolean, filterOrganizationUid?: string, sort?: Array<CoachesSortSpecification>, areOrganizationAdminsIncluded?: boolean, pageNumber?: number, pageSize?: number, searchText?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('Status', <any>status);
        }
        if (filterTeamUids) {
            filterTeamUids.forEach((element) => {
                queryParameters = queryParameters.append('Filter.TeamUids', <any>element);
            })
        }
        if (filterIsAllTeamFilterApplied !== undefined && filterIsAllTeamFilterApplied !== null) {
            queryParameters = queryParameters.set('Filter.IsAllTeamFilterApplied', <any>filterIsAllTeamFilterApplied);
        }
        if (filterOrganizationUid !== undefined && filterOrganizationUid !== null) {
            queryParameters = queryParameters.set('Filter.OrganizationUid', <any>filterOrganizationUid);
        }
        if (sort) {
            sort.forEach((element) => {
                queryParameters = queryParameters.append('Sort', <any>element);
            })
        }
        if (areOrganizationAdminsIncluded !== undefined && areOrganizationAdminsIncluded !== null) {
            queryParameters = queryParameters.set('AreOrganizationAdminsIncluded', <any>areOrganizationAdminsIncluded);
        }
        if (pageNumber !== undefined && pageNumber !== null) {
            queryParameters = queryParameters.set('PageNumber', <any>pageNumber);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (searchText !== undefined && searchText !== null) {
            queryParameters = queryParameters.set('SearchText', <any>searchText);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetCoachesQueryResult>('get',`${this.basePath}/Coaches/Coaches`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates/Invites new coach with provided information
     * UserUid Creates/Invites coach in TeamUid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public coachesPost(body?: CreateCoachCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public coachesPost(body?: CreateCoachCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public coachesPost(body?: CreateCoachCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public coachesPost(body?: CreateCoachCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/Coaches`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public coachesUidGet(uid: string, observe?: 'body', reportProgress?: boolean): Observable<GetCoachQueryResult>;
    public coachesUidGet(uid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetCoachQueryResult>>;
    public coachesUidGet(uid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetCoachQueryResult>>;
    public coachesUidGet(uid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling coachesUidGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetCoachQueryResult>('get',`${this.basePath}/Coaches/${encodeURIComponent(String(uid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public coachesUidPut(uid: string, body?: UpdateCoachCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public coachesUidPut(uid: string, body?: UpdateCoachCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public coachesUidPut(uid: string, body?: UpdateCoachCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public coachesUidPut(uid: string, body?: UpdateCoachCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling coachesUidPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Coaches/${encodeURIComponent(String(uid))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change status for coach with provided uid
     * UserUid change coach status ( denying him access to portal ) 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public coachesUidStatusPut(uid: string, body?: ChangeCoachStatusCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public coachesUidStatusPut(uid: string, body?: ChangeCoachStatusCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public coachesUidStatusPut(uid: string, body?: ChangeCoachStatusCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public coachesUidStatusPut(uid: string, body?: ChangeCoachStatusCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling coachesUidStatusPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/Coaches/${encodeURIComponent(String(uid))}/Status`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change type for coach with provided uid
     * UserUid change coach type ( coach to headCoach end reverse ) 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public coachesUidTypePatch(uid: string, body?: ChangeCoachTypeCommand, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public coachesUidTypePatch(uid: string, body?: ChangeCoachTypeCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public coachesUidTypePatch(uid: string, body?: ChangeCoachTypeCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public coachesUidTypePatch(uid: string, body?: ChangeCoachTypeCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling coachesUidTypePatch.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('patch',`${this.basePath}/Coaches/${encodeURIComponent(String(uid))}/Type`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
