import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@jsverse/transloco';
@Pipe({
  name: 'primaryMuscles',
  standalone: true,
})
export class PrimaryMusclesPipe implements PipeTransform {
  transform(primaryMuscles: string[] | undefined, activeLang?: string) {
    if (!primaryMuscles || primaryMuscles.length === 0) return;

    return primaryMuscles.join(', ');

    // return translate(primaryMuscles, undefined, activeLang);
  }
}
