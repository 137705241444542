/**
 * API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CreateGoalCommand } from '../model/createGoalCommand';
import { CreateGoalCommandResult } from '../model/createGoalCommandResult';
import { GetGoalProgressPointsQueryResult } from '../model/getGoalProgressPointsQueryResult';
import { GetGoalQueryResult } from '../model/getGoalQueryResult';
import { GetGoalsContainingMetricQueryResult } from '../model/getGoalsContainingMetricQueryResult';
import { GetUserGoalsQueryResult } from '../model/getUserGoalsQueryResult';
import { InlineResponse404 } from '../model/inlineResponse404';
import { UpdateGoalCommand } from '../model/updateGoalCommand';
import { UpdateGoalCommandResult } from '../model/updateGoalCommandResult';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class GoalsService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get all users&#x27; goals 
     * UserUid gets all his goals
     * @param userUid 
     * @param includeNotSpecified 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public goalsGet(userUid?: string, includeNotSpecified?: boolean, observe?: 'body', reportProgress?: boolean): Observable<GetUserGoalsQueryResult>;
    public goalsGet(userUid?: string, includeNotSpecified?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetUserGoalsQueryResult>>;
    public goalsGet(userUid?: string, includeNotSpecified?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetUserGoalsQueryResult>>;
    public goalsGet(userUid?: string, includeNotSpecified?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userUid !== undefined && userUid !== null) {
            queryParameters = queryParameters.set('UserUid', <any>userUid);
        }
        if (includeNotSpecified !== undefined && includeNotSpecified !== null) {
            queryParameters = queryParameters.set('IncludeNotSpecified', <any>includeNotSpecified);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetUserGoalsQueryResult>('get',`${this.basePath}/Goals`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a goal
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public goalsPost(body?: CreateGoalCommand, observe?: 'body', reportProgress?: boolean): Observable<CreateGoalCommandResult>;
    public goalsPost(body?: CreateGoalCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateGoalCommandResult>>;
    public goalsPost(body?: CreateGoalCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateGoalCommandResult>>;
    public goalsPost(body?: CreateGoalCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CreateGoalCommandResult>('post',`${this.basePath}/Goals`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public goalsUidDelete(uid: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public goalsUidDelete(uid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public goalsUidDelete(uid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public goalsUidDelete(uid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling goalsUidDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/Goals/${encodeURIComponent(String(uid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public goalsUidGet(uid: string, observe?: 'body', reportProgress?: boolean): Observable<GetGoalQueryResult>;
    public goalsUidGet(uid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetGoalQueryResult>>;
    public goalsUidGet(uid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetGoalQueryResult>>;
    public goalsUidGet(uid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling goalsUidGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetGoalQueryResult>('get',`${this.basePath}/Goals/${encodeURIComponent(String(uid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public goalsUidProgressGet(uid: string, observe?: 'body', reportProgress?: boolean): Observable<GetGoalProgressPointsQueryResult>;
    public goalsUidProgressGet(uid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetGoalProgressPointsQueryResult>>;
    public goalsUidProgressGet(uid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetGoalProgressPointsQueryResult>>;
    public goalsUidProgressGet(uid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling goalsUidProgressGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetGoalProgressPointsQueryResult>('get',`${this.basePath}/Goals/${encodeURIComponent(String(uid))}/Progress`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uid 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public goalsUidPut(uid: string, body?: UpdateGoalCommand, observe?: 'body', reportProgress?: boolean): Observable<UpdateGoalCommandResult>;
    public goalsUidPut(uid: string, body?: UpdateGoalCommand, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdateGoalCommandResult>>;
    public goalsUidPut(uid: string, body?: UpdateGoalCommand, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdateGoalCommandResult>>;
    public goalsUidPut(uid: string, body?: UpdateGoalCommand, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (uid === null || uid === undefined) {
            throw new Error('Required parameter uid was null or undefined when calling goalsUidPut.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<UpdateGoalCommandResult>('put',`${this.basePath}/Goals/${encodeURIComponent(String(uid))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param userMetricUid 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public goalsUserMetricUserMetricUidGet(userMetricUid: string, observe?: 'body', reportProgress?: boolean): Observable<GetGoalsContainingMetricQueryResult>;
    public goalsUserMetricUserMetricUidGet(userMetricUid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetGoalsContainingMetricQueryResult>>;
    public goalsUserMetricUserMetricUidGet(userMetricUid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetGoalsContainingMetricQueryResult>>;
    public goalsUserMetricUserMetricUidGet(userMetricUid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userMetricUid === null || userMetricUid === undefined) {
            throw new Error('Required parameter userMetricUid was null or undefined when calling goalsUserMetricUserMetricUidGet.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GetGoalsContainingMetricQueryResult>('get',`${this.basePath}/Goals/UserMetric/${encodeURIComponent(String(userMetricUid))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
