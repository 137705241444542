import { provideTransloco, TranslocoModule } from '@jsverse/transloco';
import {
  provideTranslocoLocale,
  TranslocoLocaleModule,
} from '@jsverse/transloco-locale';
import { isDevMode, NgModule } from '@angular/core';

import { TranslocoHttpLoader } from '../services/transloco-loader';
import { Languages } from '../constants/languages';

@NgModule({
  exports: [TranslocoModule, TranslocoLocaleModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: ['en', 'sv', 'sr-Latn', 'is'],
        fallbackLang: Languages.availableLanguages[1].id,
        defaultLang: Languages.availableLanguages[0].id,
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideTranslocoLocale({
      langToLocaleMapping: {
        en: 'en-US',
        sv: 'sv-SV',
        sr: 'sr-Latn',
        is: 'is-IS',
        // Add other languages
      },
    }),
  ],
})
export class TranslocoRootModule {}
